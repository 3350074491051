////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  10/10/2022    BBARRON       93604         Initial create
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const selectedButtonClass = 'selected';
const toggleEventType = 'gs.tabchange';
const readyEventType = 'gs.load';

/**
 * Usage: When a ButtonToggleGroup component is on the page, you can get the element from the DOM using your preferred method. document.getElementById, etc.
 * 
 * Once you have the container element, check to see if the gsComponent property is set. That will be an instance of the ButtonToggleGroup class.
 * If it's set then the component is loaded and ready to use.
 *  Events:
 *      gs.load - fires when the component is initialized and ready to use
 *          event details:
 *              - id: the id of the container element
 *              - component: an instance of the ButtonToggleGroup component, 
 *              - container: the container element,
 *              - selectedTab: the currently selected tab
 *              - selectedValue: the lowercase text of the currently selected tab
 *          You can access these details in the event handler by using "evt.detail.selectedValue" for example
 *      gs.toggle - fires when the selected tab is changed. Either by the user or by code
 *          event details:
 *              - id: the id of the container element
 *              - component: an instance of the ButtonToggleGroup component, 
 *              - container: the container element,
 *              - selectedTab: the currently selected tab
 *              - selectedValue: the lowercase text of the currently selected tab
 *          You can access these details in the event handler by using "evt.detail.selectedValue" for example
 * 
 * Once the component is loaded, the gsComponent property of the container element is set to the instance of the component class.
 * If the gsComponent property is undefined, you can listen for the gs.load event on the container element
 * 
 * Someone wishing to use this component could listen for the gs.toggle event on the container elmeent.
 * When fired they will get the currently selected tab element with "event.selectedTab".
 * From that element they can look at the text, the css classes, id, or any custom data attribute to decide how to respond to the event.
 */

class ButtonToggleGroup {
    /**
     * 
     * @param {HtmlElement} el 
     * @returns 
     */
    constructor(el) {
        if(!el) {
            return;
        }

        this.container = el;

        if(!this.container.classList.contains('gs-button-toggle-group')) {
            this.container.classList.add('gs-button-toggle-group');
        }
        
        
        // Wire up the events
        let buttons = this.getButtons();
        if(!buttons) {
            return;
        }
        buttons.forEach(btn => {
            btn.addEventListener('click', this.handleButtonClick.bind(this), false);
        });
        let componentReady = new CustomEvent(readyEventType, { 
            detail: {
                id: this.container.id, 
                component: this, 
                container: this.container,
                selectedTab: this.getSelectedButton(),
                selectedValue: this.getSelectedValue()
            }
        });
        this.container.dispatchEvent(componentReady);
    }

    getButtons() {
        let children = Array.from(this.container.children)
        return children.filter(btn => btn.classList.contains('gs-button-toggle'));
    }

    getSelectedButton() {
        let selectedButtons = Array.from(this.container.children).filter(btn => btn.classList.contains('gs-button-toggle') && btn.classList.contains(selectedButtonClass));
        if(!selectedButtons || selectedButtons.length === 0) {
            return null;
        }
        return selectedButtons[0];
    }

    getButtonValue(button) {
        if(!button) return null;
        return button.innerText.toLowerCase();
    }

    getSelectedValue() {
        return this.getButtonValue(this.getSelectedButton());
    }

    setSelectedButton(btn) {
        if(btn.classList.contains(selectedButtonClass)) {
            return;
        }
        let buttons = this.getButtons();
        buttons.forEach(currentButton => {
            if(btn == currentButton) {
                currentButton.classList.add(selectedButtonClass);
            } else {
                currentButton.classList.remove(selectedButtonClass);
            }
        });
        let buttonToggleGroupChanged = new CustomEvent(toggleEventType, {
            detail: {
                id: this.container.id, 
                component: this, 
                container: this.container,
                selectedTab: btn,
                selectedValue: this.getButtonValue(btn)
            }
        });
        this.container.dispatchEvent(buttonToggleGroupChanged);
    }

    handleButtonClick(evt) {
        evt.preventDefault();
        
        let button = evt.currentTarget;
        if(button.classList.contains(selectedButtonClass)) {
            return;
        }
    
        this.setSelectedButton(button);
    }
}

export const buttonToggleGroupScripts = {
    loadScripts: () => {
        let groups = document.querySelectorAll('.gs-button-toggle-group');
        if(!groups || groups.length === 0) {
            return;
        }

        let containers = Array.from(groups);
        containers.forEach(container => {
            // don't wire up the events if the container is in links mode rather than tabs mode
            if(container.classList.contains('gs-button-toggle-group--links')) {
                return;
            }
            let component = new ButtonToggleGroup(container);
            container.gsComponent = component;
        });
    }
};