////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  06/01/2022    BBARRON       86205         Initial create
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const factsheetSectionScripts = {
    loadScripts: () => {
        let headers = document.querySelectorAll('.gs-factsheet-section-heading');
        headers.forEach(header => {
            let parent = header.parentNode.parentNode;
            header.addEventListener('click', evt => {
                evt.preventDefault();
                parent.classList.toggle('expanded');
            });
        });
    }
};