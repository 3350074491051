////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  03/31/2022    BBARRON       82812         Initial create
//  04/27/2022    BBARRON       84447         Remove unused variable
//  05/11/2022    BBARRON       85238         Pie chart should correctly handle a single 100% slice
//  05/26/2022    BBARRON       86062         Updated to handle grouped data (arrays of arrays)
//  08/24/2022    BBARRON       90888         Fix iOS bug where transform-origin property not supported
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Creates the pie chart background circle with hatched lines
 * @param {number} radius 
 * @returns 
 */
const createChartBackground = (radius) => {
    const pattern = document.createElementNS("http://www.w3.org/2000/svg", "pattern");
    pattern.setAttribute('id', 'diagonalHatch');
    pattern.setAttribute('patternUnits', 'userSpaceOnUse');
    pattern.setAttribute('width', '8');
    pattern.setAttribute('height', '8');

    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute('x', '0');
    rect.setAttribute('y', '0');
    rect.setAttribute('width', '8');
    rect.setAttribute('height', '8');
    rect.setAttribute('fill', '#F2F2F2');
    pattern.appendChild(rect);

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute('d', 'M-2,2 l4,-4 M0,8 l8,-8 M6,10 l4,-4');
    path.setAttribute('stroke', '#A0A0A0');
    path.setAttribute('stroke-width', '1');
    pattern.appendChild(path);

    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circle.setAttribute('r', `${radius}`);
    circle.setAttribute('cx', `${radius}`);
    circle.setAttribute('cy', `${radius}`);
    circle.setAttribute('fill', `url(#diagonalHatch)`);
    return {
        pattern: pattern,
        circle: circle
    };
}

const parsePercent = (percent) => {
    if(typeof percent === 'number') {
        return percent;
    } else if (typeof percent === 'string') {
        var cleaned = percent.replace(/[^0-9\.-]/g, '');
        return parseFloat(cleaned);
    }
    return NaN;
}

/**
 * Creates the full pie chart SVG element with the standard colors
 * @param {number[]} data The array of percentages
 * @returns The SVG element
 */
const createChart = (data) => {
    if(Array.isArray(data[0])) {
        return createGroupedChart(data);
    }

    const radius = 200;
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const background = createChartBackground(radius);
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');
    svg.setAttribute('class', 'gs-piechart');
    svg.setAttribute('viewBox', `0 0 ${2 * radius} ${2 * radius}`);
    svg.appendChild(background.pattern);
    svg.appendChild(background.circle);
    
    if(data.length === 1 && Math.round(data[0]) === 100) {
        let circle = createWholePie(0, radius);
        svg.appendChild(circle);
        return svg;
    }

    let rotation = 0;
    data.forEach((percent, index) => {
        percent = parsePercent(percent);
        if(percent <= 0 || isNaN(percent)) {
            return;
        }
        let slice = createSlice(percent, index, radius, rotation);
        rotation += percentToDegrees(percent);
        svg.appendChild(slice);
    });
    return svg;
};

/**
 * Creates the full pie chart SVG element with the standard colors
 * @param {number[][]} data The array of arrays percentages
 * @returns The SVG element
 */
const createGroupedChart = (groups) => {
    const radius = 200;
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const background = createChartBackground(radius);
    svg.setAttribute('width', '100%');
    svg.setAttribute('height', '100%');
    svg.setAttribute('class', 'gs-piechart');
    svg.setAttribute('viewBox', `0 0 ${2 * radius} ${2 * radius}`);
    svg.appendChild(background.pattern);
    svg.appendChild(background.circle);

    
    if(groups.length === 1 && groups[0].length === 1 && Math.round(groups[0][0]) === 100) {
        let circle = createWholePie(0, radius);
        svg.appendChild(circle);
        return svg;
    }

    let rotation = 0;
    groups.forEach((group, index) => {
        group.forEach(percent => {
            if(percent <= 0 || isNaN(percent)) {
                return;
            }
            let slice = createSlice(percent, index, radius, rotation);
            rotation += 360 * percent / 100;
            svg.appendChild(slice);
        });
    });
    return svg;
};

/**
 * Convert degrees to radians
 * @param {number} deg The degrees
 * @returns the angle in radians
 */
const degToRadians = (deg) => {
    return deg * Math.PI / 180;
}

/**
 * Convert a percent to radians
 * @param {number} percent The angle as a percentage
 * @returns The angle in radians
 */
const percentToRadians = (percent) => {
    return percent * Math.PI / 50;
}

/**
 * Convert a percent to degrees
 * @param {number} percent The angle as a percent
 * @returns The angle in degrees
 */
const percentToDegrees = (percent) => {
    return percent * 180 / 50;
}

/**
 * Handles the math for generating a pie slice and provides arc path parameters
 * @param {number} radius the size of the pie chart
 * @param {number} percent The percent of the whole pie
 * @returns 
 */
const getSliceParemeters = (radius, percent, rotation) => {
    // Start pie chart at the top rather than the right
    const offsetAngle = degToRadians(-90) + degToRadians(rotation);
    let arcAngle = percentToRadians(percent);
    let endAngle = (offsetAngle + arcAngle) % (2 * Math.PI);
    const largeArc = percent > 50 ? '1' : '0';
    const startX = radius + radius * Math.cos(offsetAngle);
    const startY = radius + radius * Math.sin(offsetAngle);
    const endX = radius + radius * Math.cos(endAngle);
    const endY = radius + radius * Math.sin(endAngle);
    return {
        startX: startX,
        startY: startY,
        endX: endX,
        endY: endY,
        largeArc: largeArc
    };
};

/**
 * Generates an SVG path element representing one pie slice
 * @param {*} percent The percent of the pie
 * @param {*} index The index/order of this pie slice among all slices
 * @param {*} radius The chart size
 * @param {*} rotation How much this slice should be rotated based on previous slices
 * @returns The SVG Path element (The slice)
 */
const createSlice = (percent, index, radius, rotation) => {
    let d = getSliceParemeters(radius, percent, rotation);
    const slice = document.createElementNS("http://www.w3.org/2000/svg", "path");
    
    // Start at chart center, draw line to startX,startY, arc to endX, endY and then close the wedge
    slice.setAttribute('d', `M${radius} ${radius} L${d.startX} ${d.startY} A${radius} ${radius} 0 ${d.largeArc} 1 ${d.endX} ${d.endY} z`);
    slice.setAttribute('class', `gs-piechart-slice-${index + 1}`);
    
    return slice;
}

/**
 * When there's just a single slice with a value of ~100%, just create a circle instead of a pie wedge
 * @param {*} index Determines the pie chart color
 * @param {*} radius The pie chart radius
 * @returns The SVG circle element
 */
const createWholePie = (index, radius) => {
    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circle.setAttribute('r', `${radius}`);
    circle.setAttribute('cx', `${radius}`);
    circle.setAttribute('cy', `${radius}`);
    circle.setAttribute('class', `gs-piechart-slice-${index + 1}`);
    return circle;
}

const parseChartData = dataStr => {
    if(!dataStr || dataStr === '') {
        return null;
    }

    // Handles legacy parsing when data string is not in json format
    if(dataStr.indexOf('[') !== 0) {
        return dataStr.split(',').map(d => parsePercent(d));
    }

    // Handles json parsing of data string
    try {
        return JSON.parse(dataStr).map(d => {
            if(Array.isArray(d)) {
                return d.map(i => parsePercent(i));
            }
            return parsePercent(d);
        });
    } catch (err) {
        console.log("Could not parse chart data: ", err);
        return null;
    }
};

/**
 * Create a pie chart inside every element with the data-piechart-data attribute.
 * Warning: Clears the element contents, so use a container element specifically for your charts
 */
export const factsheetPieChartScripts = {
    loadScripts: () => {
        let containers = document.querySelectorAll('*[data-piechart-data]');

        containers.forEach(container => {
            let dataStr = container.dataset.piechartData;
            let data = parseChartData(dataStr);
            if(!data || data.length === 0) {
                return;
            }

            let chart = createChart(data);
            container.innerHTML = '';
            container.appendChild(chart);
        });
    }
};