////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  12/18/2020    HJordan                       Initial create
//  01/16/2020    HJordan                       Added logic to obtain SECTION filter for search based on URL
//  01/27/2021    HJordan                       Updated searchQueryValidation to allow "null" as a valid entry (search by tag)
//  04/18/2022    BBARRON       83828           Refactored mapping logic from url path to section
//  04/27/2022    BBARRON       84447           Remove unused variable
//  08/11/2022    BBARRON       90163           Update search section mapping for podcasts
//  01/23/2023    BBARRON       98536           Handle multiple tags when getting from query string
//  01/25/2023    BBARRON       98828           move search validation method to SearchValidation file
//  02/01/2023    BBARRON       99334           explicitly specify property names in getInitialSearchParams() return object
//  05/15/2023    BBARRON       105921          Ensure that content search pages with 'videos' in the url get mapped to the Videos secton
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Utility function which is used to update the "query" url parameter
 * on the window
 *
 * @param {string} value value to be set on the query url parameter
 */
export const setSearchQueryParam = value => {
  const params = new URLSearchParams(window.location.search);

  if (value) {
    params.set('query', value);
  } else {
    params.delete('query');
  }

  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};

/**
 * Utility function which is used to update the "tag" url parameter
 * on the window
 *
 * @param {string} value value to be set on the tag url parameter
 */
export const setTagParam = value => {
  const params = new URLSearchParams(window.location.search);
  params.set('tag', value);
  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};

/**
 * Utility function which is used to get the tag, section, and query
 * url parameters from the window
 *
 * In addition to checking URL params, the function will also check the
 * pathname of the URL to identify the current SECTION (Articles / Webinars) -
 * This logic will only evaluate if the section url param is not set
 *
 * @return {object} object containing the query, tags, and section url parameter values. tags is an array of strings
 */
export const getInitialSearchParams = () => {
  const params = new URLSearchParams(window.location.search);
  const query = params.get('query');
  const tagValue = params.get('tag');
  let sectionFromQueryString = params.get('section');

  // Don't add the 'all' tag here as a default. This is meant to hold only the tags that are found in the url query string.
  // If you do, it will trigger an unnecessary server-side search on page load, also tag list will default to expanded if any tag is found in the query string.
  let tagsFromQueryString = [];
  if (tagValue) {
    tagsFromQueryString = tagValue.split(',');
  }

  // Section is not specified in the query string, try to guess it from the url path
  let section = sectionFromQueryString;
  if (typeof section === "undefined" || section === null || section === '') {
    section = mapUrlPathToSection();
  }

  return { 
    query: query, 
    tags: tagsFromQueryString, 
    section: section
  };
};

/**
 * Maps the url path to a section name. eg if path contains articles, 
 * section is Articles. if path contains webinars, section is Webinars, etc.
 * @returns The section name or null
 */
function mapUrlPathToSection() {
  // mapping from "lowercase path value" to "Search Section Name"
  const mapping = {
    'articles': 'Articles',
    'commentary': 'Commentary',  // When the url contains "commentary" search the Commentary section
    'news': 'NewsAndMedia',      // When the url contains "news" search the NewsAndMedia section
    'podcast': 'FaithInvesting', // When the url contains "podcast" search the FaithInvesting section
    'videos': 'Videos',          // When the url contains "videos" search the Videos section
    'webinars': 'Webinars'
  }

  const path = window.location.pathname;
  const pathItems = path.split('/');
  for(var expectedPath in mapping) {
    if(pathItems.some(item => item.toLowerCase().includes(expectedPath)))  {
      return mapping[expectedPath];
    }
  }
  return null;
}