////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  05/16/2022    ZSunOo        83533           Initial Create
//  05/18/2022    ZSunOo        83533           Enhancement for Full factSheetFundHeader
//  05/23/2022    ZSunOo        83533           Mobile Dropdown Enhancement
//  06/02/2022    ZSunOo        86197           Design Revision Updates
//  08/04/2022    BBARRON       89814           Break out of funds header script if key elements are missing
//  09/26/2022    BBARRON       89639           Update mobile expand/collapse script to work with simplified markup
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const factSheetFundHeaderScript = {
  loadScripts: () => {
    const viewAnotherFund = document.querySelector('.view-another-fund-desktop');
    const hamburgerMenu = document.querySelector('.mobile-hamburger-menu');
    const mobileFundsNavigation = document.querySelector('.mobile-funds-navigation');

    // Exit if the component is not on the page
    if(!viewAnotherFund || !hamburgerMenu || !mobileFundsNavigation) {
      return;
    }

    if ((hamburgerMenu && mobileFundsNavigation, viewAnotherFund)) {
      const mobileFundsNavigationOverlay = mobileFundsNavigation.querySelector('.mobile-funds-navigation-overlay');
      const closeButton = mobileFundsNavigation.querySelector('.close-button');

      mobileFundsNavigation.classList.remove('active');
      [viewAnotherFund, hamburgerMenu].forEach(element =>
        element.addEventListener('click', e => {
          e.preventDefault();
          mobileFundsNavigation.classList.add('active');
        })
      );

      const closeNavTriggers = [closeButton, mobileFundsNavigationOverlay];
      closeNavTriggers.forEach(element => {
        element.classList.remove('active');
        element.addEventListener('click', e => {
          e.preventDefault();
          mobileFundsNavigation.classList.remove('active');
        });
      });
    }

    const bottomLinks = document.querySelectorAll('.bottom-row-list-item-link');
    if (!!bottomLinks.length) {
      bottomLinks.forEach(link =>
        link.addEventListener('click', e => {
          bottomLinks.forEach(l => l.classList.remove('active'));
          link.classList.add('active');
          const target = document.querySelector(e.currentTarget.href);
          if (target) target.scrollIntoView();
        })
      );
    }

    const headerBottom = document.querySelector('.header-bottom');
    const expandButton = document.querySelector('.expand-button');
    const collapseButton = document.querySelector('.collapse-button');

    // logic for  if headerBottom, expandButton, and collapseButton exists
    const isCollapsible = headerBottom && expandButton && collapseButton;

    if (isCollapsible) {
      expandButton.addEventListener('click', () => {
        expandButton.classList.remove('active');
        collapseButton.classList.add('active');
        headerBottom.classList.add('expanded');
      });

      collapseButton.addEventListener('click', () => {
        expandButton.classList.add('active');
        collapseButton.classList.remove('active');
        headerBottom.classList.remove('expanded');
      });
    }

    const accordionLinks = mobileFundsNavigation.querySelectorAll('.is-accordion > a');
    if (accordionLinks?.length) {
      accordionLinks.forEach(link => {
        link.addEventListener('click', e => {
          e.preventDefault();
          e.target.parentNode.classList.toggle('active');
        });
      });
    }
  },
  destroyScripts: () => {
    const mobileFundsNavigation = document.querySelector('.mobile-funds-navigation');
    const mobileFundsNavigationOverlay = mobileFundsNavigation.querySelector('.mobile-funds-navigation-overlay');
    const closeButton = mobileFundsNavigation.querySelector('.close-button');

    const closeNavTriggers = [closeButton, mobileFundsNavigationOverlay];
    closeNavTriggers.forEach(element => {
      element.removeEventListener('click', () => {});
    });

    const accordionLinks = mobileFundsNavigation.querySelectorAll('.is-accordion > a');
    if (accordionLinks?.length) {
      accordionLinks.forEach(link => {
        link.removeEventListener('click', () => {});
      });
    }
  }
};
