////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  04/12/2023    BBARRON       103463          Initial create
//  10/25/2023    BBARRON       117497          Push _ga cookie value to data layer when it is set
//  11/15/2023    BBARRON       118393          Require _ga cookie value to start with "GA" before sending to Tag Manager
//  12/05/2023    BBARRON       119988          Revert the above change, did not resolve the issue, caused a drop in events recoreded in GA
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { handleAttributionFormSubmit, handleAttributionEmailChange, handleGoogleAnalyticsCookieLoaded } from '../services/attribution';
import { notifyWhenCookieSet } from '../utils/cookie-utils';

export const attributionScripts = {
    loadScripts: () => {
        // Wait a bit after the page loads and then wire up any forms on the page that have an <input type="email">
        window.setTimeout(() => {
            const forms = Array.from(document.getElementsByTagName("form"));
            forms.filter(f => {
                // If the form does not have an email input field, we ignore it
                let emailInput = f.querySelector('input[type="email"]');
                if(!emailInput) {
                    return false;
                }

                emailInput.addEventListener('change', handleAttributionEmailChange, false);
                return true;
            }).forEach(f => {
                console.log('Adding attribution event listener to form', f);
                f.addEventListener('submit', handleAttributionFormSubmit, false);
            });
        }, 2000);


        // When the _ga cookies is set, pass the value to the data layer
        notifyWhenCookieSet('_ga', handleGoogleAnalyticsCookieLoaded);
    }
};