// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   12/09/2020    Jjacob        52558           Initial Create
//   02/04/2021    BBARRON       56210           Updated the handleSelectItem event handler to include an isSimpleLogin parameter
//                                               Added a launch mode (simple) and a login mode (not simple)
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const customSelectScripts = {
  loadScripts: () => {
    const selectId = 'gs_login_location_select';
    const groupName = 'gs-login-group';
    const numSelectItems = 3;
    const loginSection = 'gs-login-section';
    const loginSectionButton = 'gs-login-section-button';

    const handleSelectOpen = () => {
      document.getElementById('gs_login_location_select_selector').style.display = 'block';
    };

    const handleSelectClose = () => {
      document.getElementById('gs_login_location_select_selector').style.display = 'none';
    };

    const handleSwitchToLaunchMode = () => {
      // Hide form fields
      document.querySelectorAll(`.${loginSection}`).forEach(element => {
        element.classList.add('--hidden');
      });
      // Hide signin buttons
      document.querySelectorAll(`.${loginSectionButton}`)[0].classList.add('--hidden');
      // Show launch buttons
      document.querySelectorAll(`.${loginSectionButton}`)[1].classList.remove('--hidden');
    };

    const handleSwitchToSignInMode = () => {
      // Show form fields
      document.querySelectorAll(`.${loginSection}`).forEach(element => {
        element.classList.remove('--hidden');
      });
      // Show signin buttons
      document.querySelectorAll(`.${loginSectionButton}`)[0].classList.remove('--hidden');
      // Hide launch buttons
      document.querySelectorAll(`.${loginSectionButton}`)[1].classList.add('--hidden');
    };

    const handleSelectItem = (item, isSimpleLogin) => {
      for (let i = 1; i <= numSelectItems; i++) {
        const currGroup = document.querySelectorAll('.' + groupName + '-' + i);
        
        if (item === i) {
          // Update the dropdown menu text
          const itemName = document.getElementById(selectId + '_item_' + i).innerHTML;
          // Show/hide links for the current selection
          document.getElementById(selectId + '_input').innerHTML = itemName;
          currGroup.forEach(element => {
            element.style.display = 'block';
          });
        } else {
          currGroup.forEach(element => {
            element.style.display = 'none';
          });
        }
      }

      if(isSimpleLogin) {
        handleSwitchToLaunchMode();
      } else {
        handleSwitchToSignInMode();
      }

      handleSelectClose();
    };

    if (document.getElementById(selectId + '_input')) {
      document.getElementById(selectId + '_input_wrapper').addEventListener('click', () => {
        handleSelectOpen();
      });
      document.getElementById(selectId + '_selector').addEventListener('mouseleave', () => {
        handleSelectClose();
      });
      for (let i = 1; i <= numSelectItems; i++) {
        document.getElementById(selectId + '_item_' + i).addEventListener('click', (evt) => {
          let isSimpleLogin = evt.target.classList.contains('gs-login-simple')
          handleSelectItem(i, isSimpleLogin);
        });
      }
    }
  }
};
