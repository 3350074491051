////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  10/23/23      GCASEY        117077          Initial Create
//  11/07/2023    BBARRON       118560          Bugfix - loadScripts was getting called multiple times in storybook
//                                                       This was adding multple event listeners to drawers
//  01/18/2024    GCASEY        122462          Fix bug with cards not showing in long drawers
//  01/23/2024    GCASEY        122462          Change childNodes to children
//  01/23/2024    GCASEY        122462          Refactor Drawer expansion
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



const loadScripts = () => {
    const accordionDrawers = Array.from(document.getElementsByClassName("gs-accordion-drawer"));
    const drawerCards = Array.from(document.getElementsByClassName("gs-accordion-card"));
    const initialDrawerHeight = 2000;

    const selectionClass = "--selected";

    // Removes selection class from all drawers on the page
    const closeAllOtherDrawers = (drawer) => {
        // limit the scope of this to other draers within the same accordion menu
        const siblingDrawers = Array.from(drawer.parentElement.getElementsByClassName("gs-accordion-drawer"));
        siblingDrawers.forEach((drawer) => {
            if(drawer.classList.contains(selectionClass)) {
                drawer.classList.remove(selectionClass);
            }
        })
    }

    // Change the max-height of drawers when the content goes over the initial max-height
    const expandLongDrawers = (drawer) => {
        const childCards = drawer.querySelector(".gs-accordion-drawer-cards");
        const totalHeight = Array.from(childCards.children).map(card => card.offsetHeight).reduce((s,h) => s + h, 0);

        if(totalHeight >= initialDrawerHeight) {
            drawer.classList.add("--expanded");
        }
    }

    // Adds/Removes selection class to clicked drawer
    const drawerClicked = (e) => {
        const drawer = e.target.closest(".gs-accordion-drawer"); 
        expandLongDrawers(drawer);

        if(drawer.classList.contains(selectionClass)) {
            drawer.classList.remove(selectionClass);
        } else {
            closeAllOtherDrawers(drawer);
            drawer.classList.add(selectionClass);
        }
    }

    accordionDrawers.forEach((drawer) => {
        // Prevent drawers from being initialized twice
        // Needed because storybook calls loadScripts for every instance of the component on the page
        if(drawer.dataset.initialized !== "true") {
            drawer.addEventListener("click", drawerClicked);
            drawer.dataset.initialized = "true";
        }

     

    }, false);
    drawerCards.forEach((card) => card.addEventListener("click", (e) => e.stopPropagation()));
}



export default { loadScripts };