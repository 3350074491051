////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  05/31/2022    BBARRON       86210           Initial Create
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const factsheetOverviewScripts = {
  loadScripts: () => {
    const expandedClass = "expanded";
    const headers = document.querySelectorAll('.factsheet-overview-header-mobile');

    function isExpanded(currentHeader) {
      var parent = currentHeader.parentNode;
      return parent.classList.contains(expandedClass);
    }

    function accordionExpand(currentHeader) {
      headers.forEach(header => {
        var parent = header.parentNode;
        if(header === currentHeader) {
          parent.classList.add(expandedClass);
        } else {
          parent.classList.remove(expandedClass);
        }
      });
    }

    function accordionCollapse(currentHeader) {
      headers.forEach(header => {
        var parent = header.parentNode;
          parent.classList.remove(expandedClass);
      });
    }


    // Activate each factsheet overview header element
    headers.forEach(header => {
      var parent = header.parentNode;
      header.addEventListener('click', evt => {
        if(isExpanded(header)) {
          accordionCollapse(header);
        } else {
          accordionExpand(header);
        }
      }, false);
    });
  }
};
