/* eslint-disable spaced-comment */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  12/09/2020    Jjacob        52558           Inital Create
//  04/20/2021    BBARRON       59918           Prevented login from automatically closing when on home page
//  12/21/2022    BBARRON       96859           Added show/hide password button
//  01/31/2025    BBARRON       147759          Renamed from HeaderLogin to indicate that this code is now being deprecated
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * We're going to be phasing out this header login form that drops down when clicking the sign in button.
 * In its place we will have a dropdown menu with links to the various ODIC login urls. So we need a way to feature flag
 * the code, using the legacy login form in one case and the new login dropdown links in the other.
 */

export const legacyHeaderLoginScripts = {
  loadScripts: () => {
    /**
     * Toggles the legacy login form visibility
     */
    const handleLoginClick = () => {
      const login = document.getElementById('gs_header_login');
      login.classList.toggle('--active');
    };

    /**
     * Hide the login menu on scroll unless the user is on
     * a mobile device or they are on the site home page
     */
    function handleLoginScroll() {
      const isHome = isHomePage();
      const isDesktop = window.matchMedia('(min-width: 1024px)').matches;

      // Sticky login for mobile and home page, otherwise hide login on scroll
      if (isHome || !isDesktop) {
        return;
      }

      const login = document.getElementById('gs_header_login');
      login.classList.remove('--active');
    }

    /**
     * Determine if the user is currently on the home page
     * @return {boolean} true if the current page is the home page or site root
     */
    function isHomePage() {
      return (
        window.location.pathname === '/' || window.location.pathname === '/en' || window.location.pathname === '/en/'
      );
    }

    /**
     * Enable/disable the login button depending on whether the username/password fields are empty
     */
    function handleLoginButton() {
      const usernameVal = document.getElementById('userName').value;
      const passwordVal = document.getElementById('password').value;
      const loginButtons = document.querySelectorAll('.gs-login-section-button.--validate > .gs-link');
      if (usernameVal !== '' && passwordVal !== '') {
        for (let i = 0; i < loginButtons.length; i++) {
          loginButtons[i].classList.remove('gs-link--disabled');
        }
      } else {
        for (let i = 0; i < loginButtons.length; i++) {
          loginButtons[i].classList.add('gs-link--disabled');
        }
      }
    }

    /**
     * Click the eye icon to toggle password visiblity/masking
     * @param {MouseEvent} evt The click event
     */
    function togglePasswordVisible(evt) {
      const passwordContainer = document.querySelector('.gs-login-password-container');
      if (passwordContainer) {
        passwordContainer.classList.toggle('password-hidden');
        const passwordInput = document.querySelector('.gs-login-password-container input');
        const inputType = passwordContainer.classList.contains('password-hidden') ? 'password' : 'text';
        passwordInput.type = inputType;
      }
    }

    // Add click event handlers to password visibility buttons
    document.querySelectorAll('.gs-login-password-container > span').forEach(btn => {
      btn.addEventListener('click', togglePasswordVisible, false);
    });

    // Wire up the legacy login events if the legacy login form is present on the page
    if (document.getElementById('gs_header_login')) {
      document.getElementById('gs_header_mobile_login_link').addEventListener('click', () => {
        handleLoginClick();
      });
      document.getElementById('gs_header_login_link').addEventListener('click', () => {
        handleLoginClick();
      });
      if (document.getElementById('userName')) {
        document.getElementById('userName').addEventListener('keyup', () => {
          handleLoginButton();
        });
      }
      if (document.getElementById('password')) {
        document.getElementById('password').addEventListener('keyup', () => {
          handleLoginButton();
        });
      }

      window.addEventListener('scroll', handleLoginScroll);
    }
  }
};
