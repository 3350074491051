// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   11/02/2020    JJacob        49830           Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const toggleButtonScripts = {
  loadScripts: () => {
    const buttonIdOne = 'gs_cardlist_toggle_button_one';
    const buttonIdTwo = 'gs_cardlist_toggle_button_two';
    const handleClick = id => {
      if (id === 'one') {
        document.getElementById('gs_cardlist_toggle_button_two').classList.remove('--selected');
        document.getElementById('gs_cardlist_toggle_section_two').classList.remove('--selected');

        document.getElementById('gs_cardlist_toggle_section_one').classList.add('--selected');
        document.getElementById('gs_cardlist_toggle_button_one').classList.add('--selected');
      } else if (id === 'two') {
        document.getElementById('gs_cardlist_toggle_button_one').classList.remove('--selected');
        document.getElementById('gs_cardlist_toggle_section_one').classList.remove('--selected');

        document.getElementById('gs_cardlist_toggle_section_two').classList.add('--selected');
        document.getElementById('gs_cardlist_toggle_button_two').classList.add('--selected');
      }
    };
    if (document.getElementById(buttonIdOne)) {
      document.getElementById(buttonIdOne).addEventListener('click', () => {
        handleClick('one');
      });
      document.getElementById(buttonIdTwo).addEventListener('click', () => {
        handleClick('two');
      });
    }
  }
};
