////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  10/23/2023    BBARRON       116058        Initial create
//  11/07/2023    BBARRON       118337        Smooth scroll when jumping between elements on mobile
//  12/04/2023    BBARRON       119552        Ensure milestone query param for deep linking is not case sensitive
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { scrollToElement } from "../utils/scroll-utils";

const milestoneActiveClass = 'gs-milestones-stone--active';
const milestoneContentActiveClass = 'gs-milestones-content--active';

export const milestonesScripts = {
    loadScripts: () => {

        // This is our circuit breaker. If the milestones component is not on the page, exit immediately.
        const milestonesComponent = document.querySelector('.gs-milestones-component');
        if (!milestonesComponent) {
            return;
        }

        /**
         * Sets the relative indicator position to a given x value. x value cooresponds to the left edge of the indicator, not the center.
         * @param {number} position The x position where the indicator arrow should be moved
         */
        function setIndicatorPosition(position) {
            const indicator = document.querySelector('.gs-milestones-indicator-inner');
            indicator.style.left = `${position}px`;
        }

        /**
         * Given an x coordinate, move the arrow indicator so that it is centered on the given x position
         * @param {number} position The x coordinate where the arrow indicator should point (center)
         */
        function centerIndicatorOnPositon(position) {
            const indicator = document.querySelector('.gs-milestones-indicator-inner');
            const indicatorRect = indicator.getBoundingClientRect();
            setIndicatorPosition(position - (indicatorRect.width / 2));
        }

        /**
         * Gets the currently active milestone circle by looking for the active class
         */
        function getActiveStone() {
            return document.querySelector(`.${milestoneActiveClass}`);
        }

        /**
         * Makes the milestone content visible for the given milestone circle "stone" element
         * Optionally scrolls the user to the content that was revealed
         * @param {HtmlElement} stone The milestone circle element
         * @param {boolean} shouldScrollToContent Whether to scroll the user to the content (true for mobile, false for desktop)
         */
        function showActiveContent(stone, shouldScrollToContent) {
            if (!stone) {
                return;
            }
            let milestoneId = stone.dataset.milestone;
            const content = document.querySelectorAll('.gs-milestones-content');
            content.forEach(c => {
                if(c.dataset.milestone === milestoneId) {
                    c.classList.add(milestoneContentActiveClass);
                    if(shouldScrollToContent) {
                        scrollToElement(c, { behavior: 'smooth'});
                    }
                } else {
                    c.classList.remove(milestoneContentActiveClass)
                }
            });
        }

        /**
         * Sets the given stone to be active, makes all other stones inactive, moves the indicator arrow to this stone, and reveals the content.
         * Optionally scrolls the user down to the revealed content for this milestone
         * @param {HtmlElement} stone The given milestone circle "stone" element to make active
         * @param {boolean} shouldScrollToContent Whether to scroll the user to the content (true for mobile, false for desktop)
         * @returns 
         */
        function setActiveStone(stone, shouldScrollToContent) {
            if (!stone) {
                return;
            }
            let stones = document.querySelectorAll('.gs-milestones-navigation-items .gs-milestones-stone');
            stones.forEach((st, index) => {
                st.classList.remove(milestoneActiveClass)
            });
            stone.classList.add(milestoneActiveClass);
            showActiveContent(stone, shouldScrollToContent);
            alignIndicatorToStone(stone);
        }

        /**
         * Centers the indicator arrow horizontally under the given milestone circle "stone" element
         * @param {HtmlElement} stone The given milestone circle "stone" element
         */
        function alignIndicatorToStone(stone) {
            if (!stone) {
                stone = getActiveStone();
            }
            const position = getStoneCenter(stone);
            centerIndicatorOnPositon(position);
        }

        /**
         * Calculates the x position at the horizontal center of the milestone circle "stone" element
         * @param {HtmlElement} stone The given milestone circle "stone" element
         * @returns The center x coordinate
         */
        function getStoneCenter(stone) {
            const stoneParent = stone.parentElement.parentElement;
            const stoneRect = stone.getBoundingClientRect();
            let stonePosition = stoneRect.x - stoneParent.getBoundingClientRect().x
            let stoneCenter = stonePosition + (stoneRect.width / 2);
            return stoneCenter;
        }

        /**
         * When a stone element ic clicked, we want to make it active but also in some cases scroll the user down to the content
         * @param {MouseEvent} evt The click event
         */
        function handleStoneClick(evt) {
            evt.preventDefault();
            const stone = evt.currentTarget;

            // on mobile, scroll down to content area
            let shouldScrollToContent = window.innerWidth < 640;
            setActiveStone(stone, shouldScrollToContent);
        }

        /**
         * This component supports deep linking. If the milestone query string parameter is
         * present and matches the data-milestone attribute of any of the milestone circle elements
         * Then we say that the matching milestone is the active milestone on page load. If there is
         * no match, then we default to the first stone as active.
         * @returns The milestone element that is initially active on page load.
         */
        function getInitialMilestone() {
            
            const params = new URLSearchParams(window.location.search);
            const stones = Array.from(document.querySelectorAll('.gs-milestones-navigation-items .gs-milestones-stone'));

            let milestoneId = params.get('milestone');
            if (!milestoneId) {
                return stones[0];
            }

            milestoneId = milestoneId.toLocaleLowerCase();

            // default to the initial milestone if no matches found
            return stones.find(st => st.dataset.milestone.toLocaleLowerCase() === milestoneId) || stones[0];
        }


        // Initialize the component
        var initialMilestone = getInitialMilestone();
        setActiveStone(initialMilestone);

        // Listen for stone clicks
        let stones = document.querySelectorAll('.gs-milestones-navigation-items .gs-milestones-stone');
        stones.forEach((stone, index) => {
            stone.addEventListener('click', handleStoneClick, false);
        });

        // Listen for clicks on the return to top link
        let returnAnchor = document.querySelector('.gs-milestones-return-anchor a');
        returnAnchor.addEventListener('click', (evt) => {
            evt.preventDefault();
            const milestoneNavigationTop = document.querySelector('.gs-milestones-navigation-items');
            scrollToElement(milestoneNavigationTop, { behavior: 'smooth'});
        }, false);

        // Update the indicator arrow position on window resize
        window.addEventListener('resize', (evt) => {
            // When resizing the page, make sure the indicatoralways lines up with the active stone
            requestAnimationFrame(() => {
                alignIndicatorToStone();
            });
        }, false);
    }
};