// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   08/14/2024    GCASEY        136634          Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Allows specific links to take the user to the previous page
 * Intended for after a user submits a form
 */
export function backButtonScripts() {
    const cssClass = "gs-link--navigate-back";
    const elements = Array.from(document.getElementsByClassName(cssClass));

    elements.forEach((link) => link.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();

        if(window.history.length > 1) {
            window.history.back();
        } else {
            window.location.href = "/";
        }
    }));
}