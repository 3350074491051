// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   12/14/2020    JJacob        51694           Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const CollapseListScripts = {
  loadScripts: () => {
    const buttonId = 'gs_collapse_list_toggle_button_';
    const sectionId = 'gs_collapse_list_section_';

    const buttonClass = 'gs-collapse-list-toggle-button';
    const sectionClass = 'gs-collapse-list-toggle-section';

    const handleClick = id => {
      const active = document.querySelector('.gs-collapse-list-card.--active');
      let activeId;
      if (active) {
        activeId = active.id;
        active.classList.remove('--active');
      }
      if (activeId !== id) {
        const selected = document.querySelector('#' + id);
        selected.classList.add('--active');
      }
    };

    const handleButtonClick = id => {
      const items = document.querySelectorAll('.' + buttonClass + ', .' + sectionClass);
      items.forEach(item => {
        if (item.id === sectionId + id || item.id === buttonId + id) {
          item.classList.add('--selected');
        } else {
          item.classList.remove('--selected');
        }
      });
    };

    const elems = document.querySelectorAll('.gs-collapse-list-card');
    elems.forEach(element => {
      element.addEventListener('click', () => handleClick(element.id));
    });

    const stopLink = document.querySelectorAll('.gs-collapse-list-card a');
    stopLink.forEach(element => {
      element.addEventListener('click', e => {
        e.stopPropagation();
      });
    });

    const buttons = document.querySelectorAll('.' + buttonClass);
    buttons.forEach((button, index) => {
      button.addEventListener('click', () => {
        handleButtonClick(index + 1);
      });
    });
  }
};
