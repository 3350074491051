/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  01/14/2021     JJacob        52579           Initial Create
//  02/25/2021     KKapoor       56273           Persist filter criteria state
//  04/22/2021     BBARRON       60111           Add a no results message when no cards are visible
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { hideAllCardsInGroup, showMoreCardsInGroup } from '../card-group/CardGroup';

// The ID of the button that shows all hidden tags
const showMoreTagId = 'gs-tag-filter-show-more';

// The css class of all hidden tags
const tagHiddenClass = 'gs-tag-filter-btn--hidden';

// The css class of the currently selected tag
const tagSelectedClass = 'gs-tag-filter-btn--selected';

// In order for this component to work, the results to be filtered must be wrapped in an element with this ID
const resultsSectionId = 'gs-content-results';

// The ID of the tag filter control
const tagFilterID = 'gs-date-tag-filters-tag-filter';

// The ID of the date filter control
const dateFilterID = 'gs-date-tag-filters-date-filter';

const noResultsClass = 'gs-date-tag-filters--noresults';

/**
 * Function accepts a tag element and persists in session storage
 *
 * @param {Node} element element to be manipulated
 */
const persistSelectedTag = (element) => {
    var selectedTag = element.dataset.tag ? element.dataset.tag : 'all';
    window.sessionStorage.setItem(window.location.href + 'Tag', selectedTag);    
};

/**
 * Function accepts a date element and persists in session storage
 *
 * @param {Node} element element to be manipulated
 */
const persistSelectedDate = (element) => {
    var selectedDate = element.value ? element.value : 'all';
    window.sessionStorage.setItem(window.location.href + 'Date', selectedDate);
};

/**
 * Function to persist state of show more button in session storage
 */
const persistShowMoreTagState = () => {
    var isShowMoreTagHidden = true;
    window.sessionStorage.setItem(window.location.href + 'ShowMore', isShowMoreTagHidden);
};

/**
 * Function to restore persisted tag filter from session storage
 */
const restoreSelectedTag = () => {
    var selectedTag = window.sessionStorage.getItem(window.location.href + 'Tag');
    selectedTag = selectedTag ? selectedTag : 'all';
    const tagSelector = document.getElementById(tagFilterID);
    var targetTag = tagSelector.querySelector("button[data-tag='" + selectedTag + "']");
    if (targetTag) {
        executeFilterByTag(targetTag);
    }
};

/**
 * Function to restore persisted date filter from session storage
 */
const restoreSelectedDate = () => {
    var selectedDate = window.sessionStorage.getItem(window.location.href + 'Date');
    selectedDate = selectedDate ? selectedDate : 'all';
    const dateSelector = document.getElementById(dateFilterID);
    dateSelector.value = selectedDate;
};

/**
 * Function to restore show more button state from session storage
 */
const restoreShowMoreTagState = () => {
    const showMoreTag = document.getElementById(showMoreTagId);
    const allHiddenTags = document.getElementsByClassName(tagHiddenClass);
    var isShowMoreTagHidden = window.sessionStorage.getItem(window.location.href + 'ShowMore');
    if (isShowMoreTagHidden) {
        displayHiddenTags(allHiddenTags);
        toggleTagHidden(showMoreTag, true);
    }
};




/**
 * Function which accepts an element, and a toggle flag and
 * toggles the elements "Hidden" classes based on the toggle
 *
 * @param {Node} element The tag element to be shown/hidden
 * @param {boolean} hide if true, the tag will be hidden. If false the tag will become visible
 */
 const toggleTagHidden = (element, hide) => {
  if (hide) {
    element.classList.add(tagHiddenClass);
  } else {
    element.classList.remove(tagHiddenClass);
  }
};

/**
 * Function which accepts an element, and a toggle flag and
 * toggles the elements "Selected" classes based on the toggle
 *
 * @param {Node} element element to be manipulated
 * @param {boolean} toggle flag to identify selected or not-selected
 */
const toggleTagSelected = (element, toggle) => {
  if (toggle) {
    element.classList.add(tagSelectedClass);
  } else {
    element.classList.remove(tagSelectedClass);
  }
};

/**
 * Function which accepts a list of tags which are currently hidden, and
 * toggles them to be revealed
 *
 * @param {Array} hiddenTagList list of hidden Tag nodes
 */
const displayHiddenTags = hiddenTagList => {
  if (hiddenTagList && hiddenTagList.length > 0) {
    while (hiddenTagList.length > 0) {
      toggleTagHidden(hiddenTagList[0], false);
    }
  }
};

const showNoResultsMessage = () => {
  const noResultsMessage = document.querySelector(`#${resultsSectionId} .${noResultsClass}`);
  const results = document.querySelector(`#${resultsSectionId} > .gs-cardgroup`);
  if(noResultsMessage !== null) {
    console.log('showing the no results element');
    noResultsMessage.classList.remove('--hidden');
    results.style.display = "none";
  }
}

const hideNoResultsMessage = () => {
  const noResultsMessage = document.querySelector(`#${resultsSectionId} .${noResultsClass}`);
  const results = document.querySelector(`#${resultsSectionId} > .gs-cardgroup`);
  if(noResultsMessage !== null) {
    console.log('hiding the no results element');
    noResultsMessage.classList.add('--hidden');
    results.style.display = "block";
  }
}

/**
 * Function which targets any tags marked with "hidden" classes to be
 * updated so that they are revealed
 *
 * @param {EventTarget} event event passed by the listener
 */
export const showAllTags = event => {
  const showMoreTag = document.getElementById(showMoreTagId);
  // const allHiddenTags = document.querySelectorAll('.'+tagHiddenClass);
  const allHiddenTags = document.getElementsByClassName(tagHiddenClass);

  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (showMoreTag && allHiddenTags) {        
    displayHiddenTags(allHiddenTags);
    toggleTagHidden(showMoreTag, true);
    persistShowMoreTagState();
  }
};

/**
 * Function which accepts a tag element and sets the tag to the correct "selected"
 * classes, and ensures all other tags in the group are NOT marked as selected
 *
 * @param {Node} tag the clicked tag element
 */
export const setTagSelectedClass = tag => {
  const currentlySelectedTags = document.getElementsByClassName(tagSelectedClass);

  if (currentlySelectedTags && currentlySelectedTags.length > 0) {
    for(let i = 0; i < currentlySelectedTags.length; i += 1) {
      toggleTagSelected(currentlySelectedTags[i], false);
    }
  }

  tag.classList.add(tagSelectedClass);
};

/**
 * Client-Side Tag click handler which accepts a clicked tag element, and uses the element's
 * data-tag attribute to perform the following actions
 *
 * Set the correct classNames to style the tag as selected
 * Set any elements with matching the data-tag attribute to hidden/visible
 *
 * @param {Node} tag the clicked tag element
 */
const filterResults = () => {
  const resultsSection = document.getElementById(resultsSectionId);
  const resultCards = resultsSection ? resultsSection.querySelectorAll('.gs-card') : null;
  const tagAttributeIndicator = document.querySelector('.gs-tag-filter-btn.gs-tag-filter-btn--selected');
  const dateAttributeIndicator = document.getElementById(dateFilterID);
  const tagAttribute = tagAttributeIndicator?.dataset.tag ? tagAttributeIndicator?.dataset.tag : 'all';
  const dateAttribute = dateAttributeIndicator.value ? dateAttributeIndicator.value : 'all';
  let visibleResults = [];

  if(resultCards) {
    resultCards.forEach(card => {
      if (isDateMatch(card, dateAttribute) && isTagMatch(card, tagAttribute)) {
        card.classList.remove('gs-card--filtered-out');
        visibleResults.push(card);
      } else {
        card.classList.add('gs-card--filtered-out');
      }
    });

    // Show or hide the no results message depending on the number of visible results after filtering
    
    if(visibleResults.length > 0) {
      hideNoResultsMessage();
    } else {
      showNoResultsMessage();
    }

    hideAllCardsInGroup();
    showMoreCardsInGroup(null, 6);
  }
}

/**
 * Determines whether a given card or article matches the given date filter.
 * If the date filter is 'all', it always matches.
 * @param {HTMLElement} card  The card element which contains a data-date attribute
 * @param {string} dateFilter The desired year or 'all'
 */
const isDateMatch = (card, dateFilter) => {
  if(dateFilter === 'all') {
    return true;
  }
  const cardDateAttribute = card.dataset.date;
  return cardDateAttribute === dateFilter;
}

/**
 * Determines whether a given card or article matches the given tag filter.
 * If the tag filter is 'all', it always matches.
 * @param {HTMLElement} card  The card element which contains a data-tag attribute
 * @param {string} tagFilter The desired tag name or 'all'
 */
const isTagMatch = (card, tagFilter) => {
  if (tagFilter === 'all') {
    return true;
  }
  const cardTagAttribute = card.dataset.tag;
  return cardTagAttribute === tagFilter;
}



const executeFilterByTag = tag => {
  setTagSelectedClass(tag);
  persistSelectedTag(tag);
  filterResults();
};

const executeFilterByDate = dateSelector => {
    persistSelectedDate(dateSelector);
    filterResults();
};

export const dateTagFilterScripts = () => {
  const tagSelector = document.getElementById(tagFilterID);
  const dateSelector = document.getElementById(dateFilterID);
  const showMoreTag = document.getElementById(showMoreTagId);

  if (showMoreTag) {
      showMoreTag.addEventListener('click', showAllTags, false);
      restoreShowMoreTagState();
  }

  if (tagSelector) {
    const tags = tagSelector.querySelectorAll('button.gs-tag-filter-btn');
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].id !== showMoreTagId) {
        tags[i].addEventListener('click', () => executeFilterByTag(tags[i]), false);
      }
    }
    restoreSelectedTag();
  }

  if (dateSelector) {
      dateSelector.addEventListener('change', () => executeFilterByDate(dateSelector), false);
      restoreSelectedDate();
  }
};