// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   10/16/2023    GCASEY        110128           Initial Create
//   10/31/2023    GCASEY        117782           Fix console errors
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const formBannerScripts = () => {
    const form = document.getElementsByClassName("gs-formbanner-form")[0];
    const formBanner = document.getElementsByClassName("gs-formbanner")[0];
    const backgroundImage = document.getElementsByClassName("gs-formbanner-image")[0];
    const backgroundGraphic = document.getElementsByClassName("gs-formbanner-graphic")[0];

    if(!formBanner) {
        return;
    }

    // pixel offsets for the background images
    const imageHorizontalOffset = 50;
    const imageVerticalOffset = 0;
    const graphicHorizontalOffset = 50;
    const graphicVerticalOffset = 0;

    const initialFormHeight = form?.getBoundingClientRect().height;

    // tablet breakpoint
    const widthBreakpoint = 768;

    // sets position of the background image and background graphic 
    const setBackgroundPosition = (verticalPos) => {
        backgroundImage.style.backgroundPosition = `${imageHorizontalOffset}px ${verticalPos + imageVerticalOffset}px`;
        backgroundGraphic.style.backgroundPosition = `${graphicHorizontalOffset}px ${verticalPos + graphicVerticalOffset}px`;
    };

    // manually sets image positions to emulate scrolling with fixed backgrounds
    const scrollImages = () => {
        if(window.innerWidth < widthBreakpoint) 
            return;

        const formHeight = form.getBoundingClientRect().height;
        const formHeightChange = formHeight - initialFormHeight;

        // distance of form from top of page
        const offset = formBanner.getBoundingClientRect().top;

        if (offset < - formHeightChange) {
            setBackgroundPosition(offset + formHeightChange);
        } else if (offset < 0) {
            setBackgroundPosition(0);
        } else {
            setBackgroundPosition(offset);
        }

    }

    window.addEventListener("scroll", scrollImages);
    // recalculate image positions when the form expands/detracts
    new ResizeObserver(scrollImages).observe(formBanner);
}


