// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   07/01/2024    GCASEY        133318          Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const formSelectScripts = () => {
    const query = "select.gs-form-input, select.gs-form-cta-input";
    const selectFields = document.querySelectorAll(query);

    const onSelectChange = (e) => {
        e.target.classList.add("--selected");
    }

    selectFields.forEach((select) => {
        select.addEventListener("change", onSelectChange)
    })
}