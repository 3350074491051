/* eslint-disable no-invalid-this */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   11/11/2020    HJordan        49177          Initial Create
//   11/17/2020    HJordan                       Add conditional to check for element before adding listeners
//   08/19/2022    KKapoor        89884          Close mobile nav menu when a link is clicked
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const headerMobileScripts = {
  loadScripts: () => {
    const iconId = 'gs_header_mobile__menu_icon';
    const rootId = 'gs_header_mobile_list__root';

    const iconElement = document.getElementById(iconId);
    const rootElement = document.getElementById(rootId);

    /**
     * Establish the click events for the menu icon
     */
    if (iconElement) {
      iconElement.addEventListener('click', event => {
        if(iconElement.classList.contains('gs-nav-active')) {
          hideMobileNav();
        } else {
          showMobileNav();
        }
      });

      // close the mobile nav when one of the links is clicked. Useful for anchor links
      var mobileNavLinks = document.querySelectorAll('.gs-header-mobile-nav-item > a');
      [...mobileNavLinks].forEach(mobileLink => {
        mobileLink.addEventListener('click', hideMobileNav, false);
      });
    }

    /**
     * Hides the whole mobile nav menu
     */
    function hideMobileNav() {
      iconElement.classList.remove('gs-nav-active');
      const activeListElements = document.getElementsByClassName('gs-nav-active');
      [...activeListElements].forEach(el => el.classList.remove('gs-nav-active'));
      rootElement.classList.remove('gs-nav-active');
    }

    /**
     * Shows the mobile nav menu
     */
    function showMobileNav() {
      iconElement.classList.add('gs-nav-active');
      addListenersToList(rootId);
      rootElement.classList.add('gs-nav-active');
    }

    /**
     * EventListener callback which fires when a Back button is clicked. Based on the selected button, the
     * function determines the elements parentId (which is the ID of the active pane) and hides it and removes
     * the child listeners
     */
    function hideElementList() {
      const listToHideId = this.parentNode.id;
      const listToHideElement = document.getElementById(listToHideId);
      listToHideElement.classList.toggle('gs-nav-active');
      removeListenersFromList(listToHideId);
    }

    /**
     * EventListener callback which fires when a LI is clicked. Based on the selected list item's id,
     * the function determines which pane to display by grabbing the unique categoryID from the listItem
     * and searching for the corresponding pane with the matching category Id
     *
     * EXAMPLE : clicking listItem with ID gs_header_mobile_item__root.0 will open gs_header_mobile_list__root.0 pane
     */
    function showElementList() {
      const baseListId = 'gs_header_mobile_list__';
      const childId = this.id;
      const idCategory = childId.split('__')[1];
      const listToShowId = baseListId + idCategory;
      const listToShowElement = document.getElementById(listToShowId);

      if (listToShowElement) {
        listToShowElement.classList.toggle('gs-nav-active');
        addListenersToList(listToShowId);
      }
    }

    /**
     * Takes an element ID for a navigation pane and removes click listeners for it's children.
     * @param {string} listId The navigation object containing elements for the nav
     */
    const removeListenersFromList = listId => {
      const listElement = document.getElementById(listId);

      for (const child of listElement.childNodes) {
        if (child.nodeName === 'BUTTON') {
          child.removeEventListener('click', () => hideElementList);
        } else if (child.nodeName === 'UL') {
          const listItemNodes = child.childNodes;

          for (const item of listItemNodes) {
            item.removeEventListener('click', showElementList);
          }
        }
      }
    };

    /**
     * Takes an element ID for a navigation pane and adds click listeners to it's children.
     * If the child is a button, then a listener to dismiss the pain is added. Otherwise, the
     * function looks for all LI elements, and adds listeners to advance to the next pane
     * @param {string} listId The navigation object containing elements for the nav
     */
    const addListenersToList = listId => {
      const listElement = document.getElementById(listId);

      for (const child of listElement.childNodes) {
        if (child.nodeName === 'BUTTON') {
          child.addEventListener('click', hideElementList);
        } else if (child.nodeName === 'DIV') {
          const listItemNodes = child.getElementsByTagName('LI');
          for (const item of listItemNodes) {
            if (item.nodeName === 'LI') {
              item.addEventListener('click', showElementList);
            }
          }
        }
      }
    };
  }
};
