////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  01/25/2023    BBARRON       98828           Initial Create
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const simpleSearchBarValidationClass = 'gs-simple-search-bar-input-wrapper';
const searchBarValidationClass = 'gs-search-bar-input-wrapper';
const validationSuffix = '--error';
const messageValidationClass = 'gs-search-bar-validation--visible';

const searchElementIds = {
    searchBar: 'gs-content-search-bar',
    searchValidation: 'gs-content-search-validation',
    searchInput: 'gs-content-search-input'
};
  
const mobileSearchElementIds = {
    searchBar: 'gs-content-search-bar-mobile',
    searchValidation: 'gs-content-search-validation-mobile',
    searchInput: 'gs-content-search-input-mobile'
};

export const getSearchBar = (isMobile) => {
    return document.getElementById(isMobile ? mobileSearchElementIds.searchBar : searchElementIds.searchBar);
}

export const getSearchValidation = (isMobile) => {
    return document.getElementById(isMobile ? mobileSearchElementIds.searchValidation : searchElementIds.searchValidation);
}

export const getSearchInput = (isMobile) => {
    return document.getElementById(isMobile ? mobileSearchElementIds.searchInput : searchElementIds.searchInput);
}

/**
 * Simple validator function which checks if a given value meets the required number
 * of characters
 * @param {string} queryString Event passed by the listener
 * @return {boolean} identifies if queryString is valid or invalid
 */
export const validateSearchQuery = query => {
    if (query) {
        if (query.length > 2) {
            return true;
        }
    }

    return false;
};

/**
 * Function which takes an element, and a validation flag, and toggles the appropriate
 * validation classes on elements depending on the state of the validation flag
 * @param {boolean} isValid flag which identifies if valid or invalid
 * @param {Node} element dom element to be manipulated
 * @param {Object} searchIds ids for the search elements (either desktop or mobile)
 */
export const updateSearchValidationVisibility = (isValid, element, isMobile) => {
    const contentSearchValidation = getSearchValidation(isMobile);
    const searchBar = getSearchBar(isMobile);
    const simpleSearch = searchBar.classList.contains(simpleSearchBarValidationClass);
    const contentSiteSearch = searchBar.classList.contains(searchBarValidationClass);

    element.setAttribute('aria-invalid', !isValid);

    if (isValid) {
        contentSearchValidation.classList.remove(messageValidationClass);
        if (simpleSearch) {
            searchBar.classList.remove(`${simpleSearchBarValidationClass}${validationSuffix}`);
        } else if (contentSiteSearch) {
            searchBar.classList.remove(`${searchBarValidationClass}${validationSuffix}`);
        }
    } else {
        contentSearchValidation.classList.add(messageValidationClass);
        if (simpleSearch) {
            searchBar.classList.add(`${simpleSearchBarValidationClass}${validationSuffix}`);
        } else if (contentSiteSearch) {
            searchBar.classList.add(`${searchBarValidationClass}${validationSuffix}`);
        }
    }
};