////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  04/05/2022    BBARRON       83017         Initial create
//  04/12/2022    BBARRON       83345         Added footnotes and moved as of date out of tables
//  04/13/2022    BBARRON       83018         Added factsheet domain. Can change location of fact sheet links with config variable
//  05/11/2022    BBARRON       85240         Removed factsheet domain. No longer need to redirect fund urls to other domains.
//  07/12/2022    BBARRON       88923         Make fund links bold
//  07/19/2022    BBARRON       88352         For every as of date and every fund category, add anchor ids
//  08/01/2022    BBARRON       89485         Change anchor ids for each fund category to fundcategory-CategoryCD-ActiveTab
//  08/16/2022    BBARRON       90398         Fix three month performance column. Was populating with OneMonth data
//  08/18/2022    BBARRON       90176         Remove duplicate footnote symbols and sort numerically
//  10/13/2022    BBARRON       93634         Overhaul funds listing table for horizontal and vertical scroll on mobile
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { formatAssetValue, formatNumber, formatMorningstar, formatDate, formatTimestamp } from './formatting.js'

/////////////////////////////////////////
// Daily Performance
/////////////////////////////////////////

export const renderDailyPerformanceTable = (data) => {
    if(!data) {
        return;
    }
    let headingRow = renderDailyTableHeadingRow();
    let bodyRows = [];
    data.Categories.forEach(category => {
        let newRows = renderDailyFundCategory(category, data);
        newRows.forEach(r => bodyRows.push(r));     
    });
    
    let thead = create('thead', {children: [headingRow]});
    let tbody = create('tbody', {children: [...bodyRows]});

    return create('table', {
        classes: [],
        attributes: {'data-type': 'daily'},
        children: [thead, tbody]
    });
}

function renderDailyTableHeadingRow() {
    let html = `<th class="cell daily-cell header-cell name-cell"></th>
                <th class="cell daily-cell header-cell">Net<br />Asset<br />Value</th>
                <th class="cell daily-cell header-cell">Month To<br />Date</th>
                <th class="cell daily-cell header-cell">Year To<br />Date</th>
                <th class="cell daily-cell header-cell">One<br />Year</th>
                <th class="cell daily-cell header-cell">Three<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell daily-cell header-cell">Five<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell daily-cell header-cell">Ten<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell daily-cell header-cell">Since<br />Inception</th>
                <th class="cell daily-cell header-cell">Inception<br />Date</th>
                <th class="cell daily-cell header-cell grossExpenseRatioCol">Gross<br />Expense <br />Ratio</th>
                <th class="cell daily-cell header-cell netExpenseRatioCol">Net<br />Expense<br />Ratio</th>`;
    return create('tr', {
        classes: [],
        html: html
    });
}

function renderDailyFundCategory(category, data) {
    let headerRow = renderDailyCategoryHeader(category.Description, category.CategoryCD);
    let fundRows = [];
    category.Funds.forEach((fund, i) => {
        let newRows = renderDailyFundRow(fund, getFundFootnoteSymbols(fund, data));
        newRows.forEach(r => fundRows.push(r));
    });
    return [headerRow, ...fundRows];
}

function renderDailyCategoryHeader(categoryName, categoryCode) {

    let nameCol = create('th', {
        content: categoryName
    });

    let otherCol = create('td', {
        attributes: { 'colspan': '11' },
    });

    let cells = [nameCol, otherCol];

    

    return create('tr', {
        classes: ['category'],
        attributes: {
            'id': `fundcategory-${categoryCode.toLowerCase()}-daily`
        },
        children: cells
    });
}

function renderDailyFundRow(fund, footnoteSymbols) {
    const performanceData = fund.DailyPerformance;
    let fundName = renderFundNameCell(fund, footnoteSymbols, hasYieldRow(fund));
    let classes = ['switchableCol', 'dailyCol'];
    let values = [
        formatAssetValue(performanceData.SharePrice),
        formatNumber(performanceData.MonthToDateReturn),
        formatNumber(performanceData.YearToDateReturn),
        formatNumber(performanceData.OneYearReturn),
        formatNumber(performanceData.ThreeYearReturnAnnualized),
        formatNumber(performanceData.FiveYearReturnAnnualized),
        formatNumber(performanceData.TenYearReturnAnnualized),
        formatNumber(performanceData.SinceInceptionReturnAnnualized),
        formatTimestamp(fund.InceptionDate),
        formatNumber(fund.GrossExpenseRatio),
        formatNumber(fund.NetExpenseRatio),
    ];

    
    let valueCells = values.map((v, i) => {
        let cellClasses = classes;
        
        if(i === values.length - 2) {
            cellClasses = ['grossExpenseRatioCol', ...classes]
        }
        if(i === values.length - 1) {
            cellClasses = ['netExpenseRatioCol', ...classes]
        }
        
        return create('td', {
            classes: cellClasses,
            content: v
        });
    });

    let row = create('tr', {
        classes: ['fundInfo'],
        children: [fundName, ...valueCells]
    });

    if(hasYieldRow(fund)) {
        let yieldRow = renderYieldRow(performanceData.SevenDayAverageYield, performanceData.SevenDayGrossYield, performanceData.TradeDate, ['switchableCol','dailyCol'], 12);
        return [row, yieldRow]
    }

    return [row];
}


/////////////////////////////////////////
// Monthly Performance
/////////////////////////////////////////

export const renderMonthlyPerformanceTable = (data) => {
    //table body: Gray heading row is at top of table body
    let headingRow = renderMonthlyTableHeadingRow();
    let bodyRows = [];
    data.Categories.forEach(category => {
        let newRows = renderMonthlyFundCategory(category, data);
        newRows.forEach(r => bodyRows.push(r));     
    });

    let thead = create('thead', {children: [headingRow]});
    let tbody = create('tbody', {children: [...bodyRows]});

    return create('table', {
        attributes: {'data-type': 'monthly'},
        children: [thead, tbody]
    });
}

function renderMonthlyTableHeadingRow() {

    let html = `<th class="cell monthly-cell header-cell name-cell"></th>
                <th class="cell monthly-cell header-cell">Month To<br />Date</th>
                <th class="cell monthly-cell header-cell">Year To<br />Date</th>
                <th class="cell monthly-cell header-cell">One<br />Year</th>
                <th class="cell monthly-cell header-cell">Three<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell monthly-cell header-cell">Five<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell monthly-cell header-cell">Ten<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell monthly-cell header-cell">Since<br />Inception</th>
                <th class="cell monthly-cell header-cell">Inception<br />Date</th>
                <th class="cell monthly-cell header-cell grossExpenseRatioCol">Gross<br />Expense <br />Ratio</th>
                <th class="cell monthly-cell header-cell netExpenseRatioCol">Net<br />Expense<br />Ratio</th>`;

    return create('tr', {
        html: html
    });
}

function renderMonthlyFundCategory(category, data) {
    let headerRow = renderMonthlyCategoryHeader(category.Description, category.CategoryCD);
    let fundRows = [];
    category.Funds.forEach((fund, i) => {
        let newRows = renderMonthlyFundRow(fund, getFundFootnoteSymbols(fund, data));
        newRows.forEach(r => fundRows.push(r));
    });
    return [headerRow, ...fundRows];
}

function renderMonthlyCategoryHeader(categoryName, categoryCode) {
    let nameCol = create('th', {
        content: categoryName
    });

    let otherCol = create('td', {
        attributes: { 'colspan': '10' },
    });

    return create('tr', {
        classes: ['category'],
        attributes: {
            'id': `fundcategory-${categoryCode.toLowerCase()}-monthly`
        },
        children: [nameCol, otherCol]
    });
}

function renderMonthlyFundRow(fund, footnoteSymbols) {
    const performanceData = fund.MonthlyPerformance;
    let fundName = renderFundNameCell(fund, footnoteSymbols, hasYieldRow(fund));
    let classes = ['switchableCol', 'monthlyCol'];
    let values = [
        formatNumber(performanceData.PreTaxOneMonth),
        formatNumber(performanceData.PreTaxYearToDate),
        formatNumber(performanceData.PreTaxOneYear),
        formatNumber(performanceData.PreTaxThreeYearAnnualized),
        formatNumber(performanceData.PreTaxFiveYearAnnualized),
        formatNumber(performanceData.PreTaxTenYearAnnualized),
        formatNumber(performanceData.PreTaxInceptionAnnualized),
        formatTimestamp(fund.InceptionDate),
        formatNumber(fund.GrossExpenseRatio),
        formatNumber(fund.NetExpenseRatio),
    ];

    let valueCells = values.map((v, i) => {
        let cellClasses = classes;
        if(i === values.length - 2) {
            cellClasses = ['grossExpenseRatioCol', ...classes]
        }
        if(i === values.length - 1) {
            cellClasses = ['netExpenseRatioCol', ...classes]
        }
        return create('td', {
            classes: cellClasses,
            content: v
        });
    });

    let row = create('tr', {
        classes: ['fundInfo'],
        children: [fundName, ...valueCells]
    });

    if(hasYieldRow(fund)) {
        let yieldRow = renderYieldRow(performanceData.SevenDayAverageYield, performanceData.SevenDayGrossYield, performanceData.AsOfDate, ['switchableCol','monthlyCol'], 11);
        return [row, yieldRow]
    }

    return [row];
}


/////////////////////////////////////////
// Quarterly Performance
/////////////////////////////////////////

export const renderQuarterlyPerformanceTable = (data) => {
    let headingRow = renderQuarterlyTableHeadingRow();
    let bodyRows = [];
    data.Categories.forEach(category => {
        let newRows = renderQuarterlyFundCategory(category, data);
        newRows.forEach(r => bodyRows.push(r));     
    });

    let thead = create('thead', {children: [headingRow]});
    let tbody = create('tbody', {children: [...bodyRows]});

    return create('table', {
        attributes: {'data-type': 'quarterly'},
        children: [thead, tbody]
    });
}

function renderQuarterlyTableHeadingRow() {

    let html = `<th class="cell quarterly-cell header-cell name-cell"></th>
                <th class="cell quarterly-cell header-cell">Three<br />Month</th>
                <th class="cell quarterly-cell header-cell">Year To<br />Date</th>
                <th class="cell quarterly-cell header-cell">One<br />Year</th>
                <th class="cell quarterly-cell header-cell">Three<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell quarterly-cell header-cell">Five<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell quarterly-cell header-cell">Ten<br />Year<br /><span class="annualized">(Annualized)</th>
                <th class="cell quarterly-cell header-cell">Since<br />Inception</th>
                <th class="cell quarterly-cell header-cell">Inception<br />Date</th>
                <th class="cell quarterly-cell header-cell grossExpenseRatioCol">Gross<br />Expense <br />Ratio</th>
                <th class="cell quarterly-cell header-cell netExpenseRatioCol">Net<br />Expense<br />Ratio</th>`;

    return create('tr', {
        html: html
    });
}

function renderQuarterlyFundCategory(category, data) {
    let headerRow = renderQuarterlyCategoryHeader(category.Description, category.CategoryCD);
    let fundRows = [];
    category.Funds.forEach((fund, i) => {
        let newRows = renderQuarterlyFundRow(fund, getFundFootnoteSymbols(fund, data));
        newRows.forEach(r => fundRows.push(r));
    });
    return [headerRow, ...fundRows];
}

function renderQuarterlyCategoryHeader(categoryName, categoryCode) {
    let nameCol = create('th', {
        content: categoryName
    });

    let otherCol = create('td', {
        attributes: { 'colspan': '10' },
    });

    return create('tr', {
        classes: ['category'],
        attributes: {
            'id': `fundcategory-${categoryCode.toLowerCase()}-quarterly`
        },
        children: [nameCol, otherCol]
    });
}

function renderQuarterlyFundRow(fund, footnoteSymbols) {
    const performanceData = fund.QuarterlyPerformance;
    let fundName = renderFundNameCell(fund, footnoteSymbols, hasYieldRow(fund));
    let classes = ['switchableCol', 'quarterlyCol'];
    let values = [
        formatNumber(performanceData.PreTaxThreeMonth),
        formatNumber(performanceData.PreTaxYearToDate),
        formatNumber(performanceData.PreTaxOneYear),
        formatNumber(performanceData.PreTaxThreeYearAnnualized),
        formatNumber(performanceData.PreTaxFiveYearAnnualized),
        formatNumber(performanceData.PreTaxTenYearAnnualized),
        formatNumber(performanceData.PreTaxInceptionAnnualized),
        formatTimestamp(fund.InceptionDate),
        formatNumber(fund.GrossExpenseRatio),
        formatNumber(fund.NetExpenseRatio),
    ];

    let valueCells = values.map((v, i) => {
        let cellClasses = classes;
        if(i === values.length - 2) {
            cellClasses = ['grossExpenseRatioCol', ...classes]
        }
        if(i === values.length - 1) {
            cellClasses = ['netExpenseRatioCol', ...classes]
        }
        return create('td', {
            classes: cellClasses,
            content: v
        });
    });

    let row = create('tr', {
        classes: ['fundInfo'],
        children: [fundName, ...valueCells]
    });

    if(hasYieldRow(fund)) {
        let yieldRow = renderYieldRow(performanceData.SevenDayAverageYield, performanceData.SevenDayGrossYield, performanceData.AsOfDate, ['switchableCol','quarterlyCol'], 11);
        return [row, yieldRow]
    }

    return [row];
}


/////////////////////////////////////////
// Morningstar Performance
/////////////////////////////////////////


export const renderMorningstarPerformanceTable = (data) => {
    let headingRow = renderMorningstarTableHeadingRow();
    let bodyRows = [];
    data.Categories.forEach(category => {
        let newRows = renderMorningstarFundCategory(category, data);
        newRows.forEach(r => bodyRows.push(r));     
    });

    
    let thead = create('thead', {children: [headingRow]});
    let tbody = create('tbody', {children: [...bodyRows]});

    return create('table', {
        attributes: {'data-type': 'morningstar'},
        children: [thead, tbody]
    });
}

function renderMorningstarTableHeadingRow() {

    let html = `<th class="cell morningstar-cell header-cell morningstar-cell name-cell"></th>
                <th class="cell morningstar-cell header-cell morningstar-cell">Category</th>
                <th class="cell morningstar-cell header-cell morningstar-cell">Overall Rating</th>
                <th class="cell morningstar-cell header-cell morningstar-cell">3 Year Rating</th>
                <th class="cell morningstar-cell header-cell morningstar-cell">5 Year Rating</th>
                <th class="cell morningstar-cell header-cell morningstar-cell">10 Year Rating</th>`;

    return create('tr', {
        html: html
    });
}

function renderMorningstarFundCategory(category, data) {
    let headerRow = renderMorningstarCategoryHeader(category.Description, category.CategoryCD);
    let fundRows = [];
    category.Funds.forEach((fund, i) => {
        let newRows = renderMorningstarFundRow(fund, getFundFootnoteSymbols(fund, data));
        newRows.forEach(r => fundRows.push(r));
    });
    return [headerRow, ...fundRows];
}

function renderMorningstarCategoryHeader(categoryName, categoryCode) {
    let nameCol = create('th', {
        content: categoryName
    });

    let otherCol = create('td', {
        attributes: { 'colspan': '5' },
    });

    return create('tr', {
        classes: ['category'],
        attributes: {
            'id': `fundcategory-${categoryCode.toLowerCase()}-morningstar`
        },
        children: [nameCol, otherCol]
    });
}

function renderMorningstarFundRow(fund, footnoteSymbols) {
    const performanceData = fund.MorningstarRating;
    let fundName = renderFundNameCell(fund, footnoteSymbols, false);
    let valueCells = [
        create('td', { 
            content: performanceData.MorningstarCategoryDescription
        }),
        drawStars(performanceData.MorningstarOverallRating, performanceData.MorningstarOverallFunds),
        drawStars(performanceData.MorningstarThreeYearRating, performanceData.MorningstarThreeYearFunds),
        drawStars(performanceData.MorningstarFiveYearRating, performanceData.MorningstarFiveYearFunds),
        drawStars(performanceData.MorningstarTenYearRating, performanceData.MorningstarTenYearFunds),
    ];

    

    let row = create('tr', {
        children: [fundName, ...valueCells]
    });

    return [row];
}

const drawStars = function(numStars, numFunds) {

    const rating = formatMorningstar(numStars, numFunds);
    if(rating === 'N/A') {
        return create('td', {
            classes: ['switchableCol','morningstarCol'],
            content: rating
        });
    }
    
    const star = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24"><path style="fill: #9DC7EA" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>`;
    let stars = [];
    for(let i = 0; i < numStars; i += 1) {
        stars.push(star);
    }
    let html = stars.join('');
    let starsContainer = create('div', {
        classes: ['stars'],
        html: html
    });
    let text = document.createTextNode(` of ${numFunds}`);
    return create('td', {
        classes: ['switchableCol','morningstarCol','ratingCol'],
        children: [starsContainer, text]
    });
}

/////////////////////////////////////////
// Utility
/////////////////////////////////////////

export const renderAsOfDate = function (asOfDate, container, isMorningstar) {
    container.innerHTML = '';
    let content;
    if(!isMorningstar) {
        content = create('div', {
            classes: ['funds-listing-asof-date'],
            content: `As of Date: ${formatDate(asOfDate)}`
        });
    } else {
        let row1 = create('div', {
            content: `Morningstar Ratings (number of funds in category). As of Date: ${formatDate(asOfDate)}`
        });
    
        let row2 = create('div', {
            content: 'Based on Risk Adjusted Return'
        });
    
        content = create('div', {
            classes: ['funds-listing-asof-date'], 
            children: [row1, row2]
        });
    }
    
    container.appendChild(content);
}

/**
 * A compareFn for Array.prototype.sort that compares the integer value of the two numbers
 * @param {*} a The first number to compare. Either a number or a string representation of a number
 * @param {*} b The second number to compare. Either a number or a string representation of a number
 * @returns negative number if b > a, positive number if a > b
 */
function integerSortFunction (a, b) {
    let first = typeof a === 'string' ? parseInt(a, 10): a;
    var second = typeof b === 'string' ? parseInt(b, 10): b;
    return first - second;
}

function getFundFootnoteSymbols(fund, data) {
    const ticker = fund.TickerSymbol;
    const footnoteSymbols = data.FundNotesMap[ticker];
    if(footnoteSymbols) {
        // remove duplicates
        let uniqueSymbols = [...new Set(footnoteSymbols)];
        // sort numerically
        let sortedUnique = uniqueSymbols.sort(integerSortFunction);
        return sortedUnique.join(',');
    }
    return null;
}

/**
 * Renders the first cell of a fund row with the fund name, link, and footnotes
 * @param {*} fund The fund data
 * @param {string[]} footnoteSymbols The list of footnote numbers/supercripts
 * @returns The first cell in the row
 */
function renderFundNameCell(fund, footnoteSymbols, hasAdditionalRow) {
    let link = create('a', {
        classes: ['gs-link','gs-link--primary','gs-link--small','gs-link--green', 'gs-link--bold'],
        attributes: {
            href: fund.FundInfoLink,
        },
        html: `${fund.Description}`
    });

    let cellAttributes = {};
    if(hasAdditionalRow) {
       cellAttributes = { 'rowspan': "2" };
    }

    let children = [link];
    if(footnoteSymbols) {
        let footnotes = create('sup', {content: footnoteSymbols});
        children = [link, footnotes];
    }
    
    return create('th', {
        classes: ['fundInfoCol','fundNameCol'],
        attributes: cellAttributes,
        children: children
    });
}

function create(type, {classes, attributes, content, children, html}) {
    var element = document.createElement(type);
    
    if(classes) {
        classes.forEach(c => element.classList.add(c));
    }

    if(attributes) {
        for(let property in attributes) {
            element.setAttribute(`${property}`, `${attributes[property]}`);
        }
    }

    if(content) {
        element.textContent = content;
    }

    if(children) {
        children.forEach(c => {
            element.appendChild(c);
        });
    }

    if(html) {
        element.innerHTML = html;
    }

    return element;
};

function hasYieldRow(fund) {
    return fund.Description.toLowerCase().includes("money market");
}

function renderYieldRow(average, gross, asOfDate, classes, columns) {
    let yieldMessage = `7-day current annualized yield (net) = ${formatNumber(average)}, 7-day current annualized yield (gross) = ${formatNumber(gross)}, as of ${formatTimestamp(asOfDate)}`;
    let yieldCol = create('td', {
        classes: classes,
        attributes: {'colspan': columns - 1},
        content: yieldMessage
    });
    return create('tr', {
        classes: ['fundYieldInfo'],
        children: [yieldCol]
    });
}

export const renderFootnotes = (data, container) => {
    container.innerHtML = '';
    let children = [];
    const footnotes = data.Footnotes.map((footnote, index) => { 
        // get all data together
        return {
            text: footnote, 
            number: index + 1, 
            className: !!data.FootnotesCssClass[index] ? [data.FootnotesCssClass[index]] : null
        };
    }).forEach(footnote => {
        // convert to elements
        children.push(create('br', {}));
        children.push(create('sup', { content: footnote.number })); 
        children.push(create('span', { classes: footnote.className, content: footnote.text }));
        children.push(create('br', {}));
    });

    // append to container element
    children.forEach(c => container.appendChild(c));
}