////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  12/10/2020    HJordan                       Initial create
//  04/18/2022    BBARRON       83828           Add site param to toggle between brand and funds search
//  01/12/2023    BBARRON       97891           Add ability to hide revealed tags
//  01/18/2023    BBARRON       97895           Add ability to select multiple tags, only hide unselected tags
//  01/23/2023    BBARRON       98536           Remove excessive console logging
//  02/01/2023    BBARRON       99334           Don't activate apply button until a tag is clicked
//  02/07/2023    BBARRON       99963           Make sure all tag gets selected
//  06/01/2023    GCASEY        106291          Remove apply button and add automatic search when a tag is clicked   
//  08/14/2024    GCASEY        137177          Update server side tag toggling to use updated browser APIs 
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { getInitialSearchParams, setTagParam } from '../utils/search-utils';
import { searchContentAndUpdateResults } from '../content-search-results/ContentSearchResults';

const showMoreTagId = 'gs-tag-filter-show-more';
const showLessTagId = 'gs-tag-filter-show-less';
const applyTagsId = 'gs-tag-filter-apply-tags';

const tagContainerId = 'gs-tag-filter-container';
const tagContainerCollapsedClass = 'gs-tag-filters-listGroup--collapsed';

const tagClass = 'gs-tag-filter-btn';
const tagHiddenClass = 'gs-tag-filter-btn--hidden';
const tagSelectedClass = 'gs-tag-filter-btn--selected';
const resultsSectionId = 'gs-content-search-results';

const clientSideFilterID = 'gs-tag-filters-client-side';
const serverSideFilterID = 'gs-tag-filters-server-side';

const getClientSideTagsGroup = () => {
  const clientSideTagsGroup = document.getElementById(clientSideFilterID);
  if (clientSideTagsGroup) {
    return {
      component: clientSideTagsGroup,
      tags: Array.from(clientSideTagsGroup.querySelectorAll('button.gs-tag-filter-btn:not(.gs-tag-filter-btn--special)')),
      allTag: findTag('all', clientSideTagsGroup),
      showMoreTag: document.getElementById(showMoreTagId),
      showLessTag: document.getElementById(showLessTagId),
      applyButton: document.getElementById(applyTagsId)
    };
  }
  return null;
}

const getServerSideTagsGroup = () => {
  const serverSideTagsGroup = document.getElementById(serverSideFilterID);
  if(serverSideTagsGroup) {
    return {
      component: serverSideTagsGroup,
      tags: Array.from(serverSideTagsGroup.querySelectorAll('button.gs-tag-filter-btn:not(.gs-tag-filter-btn--special)')),
      allTag: findTag('all', serverSideTagsGroup),
      showMoreTag: document.getElementById(showMoreTagId),
      showLessTag: document.getElementById(showLessTagId),
      applyButton: document.getElementById(applyTagsId),
      site: serverSideTagsGroup.dataset.site || 'brand'
    };
  }
  return null;
}

/**
 * Gets all selected tags as an array of strings
 * @returns {string[]} All selected tag names
 */
export const getAllSelectedTags = () => {
  return getSelectedTags(true).map(element => element.dataset.tag);
};

/**
 * If the all tag is selected, return that tag. If not, return all the selected tags
 * @returns The selected tag(s)
 */
const getSelectedTags = (includeAllTag) => {
  const allTag = findTag('all');
  let selectedTags = Array.from(document.getElementsByClassName(tagSelectedClass));

  // remove special tags like all/more/less/apply
  selectedTags = selectedTags.filter(tag => !tag.classList.contains('gs-tag-filter-btn--special'));

  // include the all tag if desired
  if(includeAllTag && allTag.classList.contains(tagSelectedClass)) {
    selectedTags.push(allTag);
  }

  return selectedTags;
}


/**
 * Gets all non-special tags (the all tag, show more, show less, and apply)
 * @returns The tags
 */
const getAllTags = () => {
  let allTags = Array.from(document.getElementsByClassName(tagClass));
  allTags = allTags.filter(tag => !tag.classList.contains('gs-tag-filter-btn--special'));
  return allTags;
}

/**
 * Finds a tag by name, includes special tags
 * @param {string} tag The tag name
 * @returns The tag or null
 */
const findTag = (tag, container) => {
  container = container || document;
  return document.querySelector(`.${tagClass}[data-tag="${tag.toLowerCase()}"]`)
}

/**
 * Determines which tags to hide when the less button is clicked.
 * If
 */
const getTagsToHide = () => {
  const container = document.getElementById(tagContainerId);
  const numTagsToKeep = parseInt(container.dataset.startingTagNumber);
  let allTags = Array.from(document.getElementsByClassName(tagClass));
  allTags = allTags.filter(tag => !tag.classList.contains('gs-tag-filter-btn--special'));

  let selectedTags = getSelectedTags();
  let unselectedTags = allTags.filter(tag => !selectedTags.some(t => t == tag));
  // If you already have enough tags just among the selected ones, you can hide all the unselected ones
  if(selectedTags.lenght >= numTagsToKeep) {
    return unselectedTags;
  }

  // keep all selected tags and the first n unselected tags until you have <numTagsToKeep> in the list
  let tagsToKeep = [...selectedTags];
  unselectedTags.forEach(tag => {
    if(tagsToKeep.length < numTagsToKeep) {
      tagsToKeep.push(tag);
    }
  });

  // Return the list of all tags not on the tags to keep list
  return unselectedTags.filter(tag => !tagsToKeep.some(t => t == tag));
}

/**
 * Function which accepts an element, and a toggle flag and
 * toggles the elements "Hidden" classes based on the toggle
 *
 * @param {Node} element element to be manipulated
 * @param {boolean} toggle flag to identify hidden or visible
 */
const toggleTagHidden = (element, toggle) => {
  if (toggle) {
    element.classList.add(tagHiddenClass);
  } else {
    element.classList.remove(tagHiddenClass);
  }
};

/**
 * Handles selection of each tag
 *
 * @param {Node} element element to be manipulated
 */
const handleTagSelection = (element) => {

  // If you click the 'all' tag, deselect all other tags
  if(element.dataset.tag === 'all') {
    getAllTags().forEach(tag => tag.classList.remove(tagSelectedClass));
    element.classList.add(tagSelectedClass);
    updateLessButtonVisibility();
    return;
  }

  // Otherwise toggle the tag
  let wasSelected = element.classList.contains(tagSelectedClass);
  if(!wasSelected) {
    selectTag(element);
  } else {
    // when deselecting the last selected tag, you may need to select the 'all' tag
    deselectTag(element);
  }
};

/**
 * Marks the given tag as selected, 
 * if you provide a tag name instead of tag element, 
 * an attempt will be made to find the correct element.
 * @param {string|HtmlElement} element The tag element or tag name to select
 */
export const selectTag = (element) => {
  if(typeof element === "string") {
    element = findTag(element);
  }
  let allTag = findTag('all');
  element.classList.add(tagSelectedClass);
  allTag.classList.remove(tagSelectedClass);

  // Determine whether the less button should be visible
  updateLessButtonVisibility();
}

export const selectAllTag = () => {
  let allTag = findTag('all');
  allTag.classList.add(tagSelectedClass);
  getAllTags().forEach(tag => tag.classList.remove(tagSelectedClass));
}

const deselectTag = (element) => {
  let allTag = findTag('all');
  element.classList.remove(tagSelectedClass);
  let selectedTags = getSelectedTags(false);

  // if no tags are selected after this tag gets deselected, then select the "all" tag
  if(selectedTags.length === 0) {
    allTag.classList.add(tagSelectedClass);
  }

  // Determine whether the less button should be visible
  updateLessButtonVisibility();
}

const updateLessButtonVisibility = () => {
  const tagContainer = document.getElementById(tagContainerId);
  const showLessButton = document.getElementById(showLessTagId);

  if (tagContainer.classList.contains(tagContainerCollapsedClass)) {
    showLessButton.classList.add(tagHiddenClass);
    return;
  }

  let tagsToHide = getTagsToHide();
  if(tagsToHide.length === 0) {
    showLessButton.classList.add(tagHiddenClass);
    return;
  }

  showLessButton.classList.remove(tagHiddenClass);
}


/**
 * Function which accepts a list of tags which are currently hidden, and
 * toggles them to be revealed
 *
 * @param {Array} tagsToShow list of hidden Tag nodes
 */
const displayHiddenTags = tagsToShow => {
  if (tagsToShow && tagsToShow.length > 0) {
    for (let i = 0; i < tagsToShow.length; i++) {
      toggleTagHidden(tagsToShow[i], false);
    }
  }
};

/**
 * Function which accepts a list of tags which are currently revealed, and
 * toggles them to be hidden
 *
 * @param {Array} tagsToHide list of hidden Tag nodes
 */
const hideTags = tagsToHide => {
  if (tagsToHide && tagsToHide.length > 0) {
    for (let i = 0; i < tagsToHide.length; i++) {
      toggleTagHidden(tagsToHide[i], true);
    }
  }
}

/**
 * Function which targets any tags marked with "hidden" classes to be
 * updated so that they are revealed
 *
 * @param {EventTarget} event event passed by the listener
 */
export const showAllTags = event => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  const allHiddenTags = Array.from(document.getElementsByClassName(tagHiddenClass));
  const tagContainer = document.getElementById(tagContainerId);
  const showMoreButton = document.getElementById(showMoreTagId);
  const showLessButton = document.getElementById(showLessTagId);

  tagContainer.classList.remove(tagContainerCollapsedClass);
  showMoreButton.classList.add(tagHiddenClass);
  showLessButton.classList.remove(tagHiddenClass);

  if (allHiddenTags) {
    displayHiddenTags(allHiddenTags);
  }
};

/**
 * Function which targets any tags marked with "revealed" classes to be
 * updated so that they are hidden
 *
 * @param {EventTarget} event event passed by the listener
 */
export const hideExtraTags = event => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  const tagsToHide = getTagsToHide();
  if(tagsToHide && tagsToHide.length > 0) {
    const tagContainer = document.getElementById(tagContainerId);
    const showMoreButton = document.getElementById(showMoreTagId);
    const showLessButton = document.getElementById(showLessTagId);

    // collapse the list
    tagContainer.classList.add(tagContainerCollapsedClass);
    showMoreButton.classList.remove(tagHiddenClass);
    showLessButton.classList.add(tagHiddenClass);
    hideTags(tagsToHide);
  }
}

/**
 * Function which accepts a tag element and sets the tag to the correct "selected"
 * classes, and ensures all other tags in the group are NOT marked as selected
 *
 * @param {Node} tags the clicked tag element
 */
export const setTagSelectedClass = tags => {
  tags.forEach(tag => {
    let foundTag = findTag(tag);
    if(!foundTag) { return; }
    foundTag.classList.add(tagSelectedClass);
  });
};

/**
 * Server-Side Tag click handler which accepts a clicked tag element, and uses the element's
 * data-tag attribute to perform the following actions
 *
 * Update the window query params with the tag's value
 * Set the correct classNames to style the tag as selected
 * Execute an API request for content matching the given tag
 *
 * @param {Node} tag the clicked tag element
 */
const executeServerSideFilterBySelectedTags = (site) => {
  const { section, query } = getInitialSearchParams();

  const selectedTags = getAllSelectedTags(); // Array of strings based on the tag's data-tag attribute

  setTagParam(selectedTags.join(','));
  searchContentAndUpdateResults(section, query, selectedTags, site);
};

/**
 * Client-Side Tag click handler which accepts a clicked tag element, and uses the element's
 * data-tag attribute to perform the following actions
 *
 * Set the correct classNames to style the tag as selected
 * Set any elements with matching the data-tag attribute to hidden/visible
 *
 * @param {Node} tag the clicked tag element
 */
const executeClientSideFilterBySelectedTags = () => {
  const resultsSection = document.getElementById(resultsSectionId);
  const resultsGroups = resultsSection ? resultsSection.querySelectorAll('.gs-cardgroup') : null;
  const selectedTags = getAllSelectedTags(); // Array of strings based on the tag's data-tag attribute

  if (resultsGroups) {
    resultsGroups.forEach(group => {
      const groupTagDataAttribute = group.dataset.tag;
      if (selectedTags.includes('all')) {
        group.classList.remove('gs-cardgroup--hidden');
      } else if (!selectedTags.includes(groupTagDataAttribute)) {
        group.classList.add('gs-cardgroup--hidden');
      } else {
        group.classList.remove('gs-cardgroup--hidden');
      }
    });
  }
};

/**
 * Enables all tags in a tag group to be clickable. 
 * 
 * @param {Node[]} tagGroup 
 */
const enableTagGroup = (tagGroup) => {
    tagGroup.tags.forEach(tag => tag.disabled = false);
    tagGroup.allTag.disabled = false;
}

/**
 * Disables all tags in a tag group from being clickable.
 * 
 * @param {Node[]} tagGroup 
 */
const disableTagGroup = (tagGroup) => {
    tagGroup.tags.forEach(tag => tag.disabled = true);
    tagGroup.allTag.disabled = true;
}

/**
 *  Handles click event for all tags. Calls corresponding filtering function for client and server side tags.
 * @param {Node} tag 
 */
const handleTagClick = (tag) => {
  const container = tag.parentNode.parentNode.parentNode;
  if(container.id === serverSideFilterID) {
    const serverSideTagsGroup = getServerSideTagsGroup();
    // disable tags while searching
    disableTagGroup(serverSideTagsGroup);
    handleTagSelection(tag);
    executeServerSideFilterBySelectedTags(serverSideTagsGroup.site);
  } else {
    handleTagSelection(tag);
    executeClientSideFilterBySelectedTags(tag);
  }
}

/**
 * Adds listener to check when the search results are populated and then enables the tags to be clickable
 * @param {Node[]} serverSideTagsGroup 
 * @param {Node} resultSection 
 */
const enableServerSideTags = (serverSideTagsGroup, resultSection) => {
  const mutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        if (mutation.addedNodes.length > 0) {
          enableTagGroup(serverSideTagsGroup);
        }

        if (mutation.removedNodes.length > 0) {
          disableTagGroup(serverSideTagsGroup);
        }
      }
    }
  };
  const observer = new MutationObserver(mutationCallback);
  observer.observe(resultSection, {
    childList: true,
    subtree: true
  });
}

/**
 * Scripts which must be executed on page load in order to establish the interaction capabilities
 * of Tag Filters. Tag filters have the capacity to trigger both ClientSide and ServerSide capabilities - thus
 * setting the appropriate ID on the tag group will facilitate the correct interaction for a given need
 *
 * Client Side Interaction | Trigger show/hide of elements on the page based on data-tag
 * Server Side Interaction | Trigger API request for data based on data-tag
 *
 */
export const tagFilterScripts = () => {
  const resultSection = document.getElementById(resultsSectionId);

  const clientSideTagsGroup = getClientSideTagsGroup();
  if (clientSideTagsGroup) {
    // wire up tag clicks
    clientSideTagsGroup.tags.forEach(tag => tag.addEventListener('click', () => handleTagClick(tag), false));
    clientSideTagsGroup.allTag.addEventListener('click', () => handleTagClick(clientSideTagsGroup.allTag), false);
    clientSideTagsGroup.showMoreTag.addEventListener('click', showAllTags);
    clientSideTagsGroup.showLessTag.addEventListener('click', hideExtraTags);
  }

  const serverSideTagsGroup = getServerSideTagsGroup();
  if (serverSideTagsGroup) {
    // wire up tag clicks
    serverSideTagsGroup.tags.forEach(tag => tag.addEventListener('click', () => handleTagClick(tag), false));
    serverSideTagsGroup.allTag.addEventListener('click', () => handleTagClick(serverSideTagsGroup.allTag), false);
    serverSideTagsGroup.showMoreTag.addEventListener('click', showAllTags);
    serverSideTagsGroup.showLessTag.addEventListener('click', hideExtraTags);
    enableServerSideTags(serverSideTagsGroup, resultSection);
  }
};