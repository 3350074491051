////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  11/17/2020    HJordan                       inclusion of mobileHeaderScripts
//  12/09/2020    Jjacob        52558           Added login related scripts
//  12/14/2020    JJacob        51694           Added toggle colapse list
//  12/14/2020    HJordan       49021           Added support for Site Search / Content Search / Tag Filters / CardGroups
//  01/19/2022    BBARRON       75636           Included Funds Listing Script
//  05/13/2022    ZSunOo        77752           Added Price Distribution Graph Scripts
//  05/24/2022    ZSunOo        82637           Added horizontal Table Scripts
//  07/19/2022    BBARRON       88352           Add simulated anchor ids property to funds listing component
//  10/10/2022    BBARRON       93604           Added ButtonToggleGroup component
//  02/01/2023    BBARRON       99334           Renamd updatePageSearchContent to initializeContentSearch
//  04/12/2023    BBARRON       103463          Added attribution scripts - listens for all form submits
//  06/13/2023    GCASEY        107567          Add alert scripts
//  10/16/2023    GCASEY        110128          Add form banner scripts
//  10/24/2023    BBARRON       116058          Add Milestones component scripts
//  10/23/2023    GCASEY        117077          Add Accordion Drawer and Accordion Toggle Scripts   
//  07/01/2024    GCASEY        133318          Add form select scripts
//  08/14/2024    GCASEY        136634          Add back button scripts
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { cardGroupScripts } from './card-group/CardGroup';
import { contentSearchScripts, initializeContentSearch } from './content-search/ContentSearch';
import { customSelectScripts } from './custom-select/CustomSelect';
import { FAQScripts } from './faq/FAQ';
import { headerLoginScripts } from './header-login/HeaderLogin';
import { headerMobileScripts } from './header-mobile/HeaderMobile';
import { headerScripts } from './header/Header';
import { siteSearchScripts } from './content-search/ContentSearch';
import { tagFilterScripts } from './tag-filters/TagFilters';
import { toggleButtonScripts } from './toggle-button/ToggleButton';
import { CollapseListScripts } from './collapse-list/CollapseList';
import { toggleRadioScripts } from './radio-switch/RadioSwitch';
import { carouselScripts } from './carousel/Carousel';
import { dateTagFilterScripts } from './date-tag-filters/DateTagFilters';
import { wizardScripts } from './wizard/Wizard';
import { siteAnimations } from './site-animations/SiteAnimations';
import { fundsListingScripts } from './funds-listing/FundsListing';
import { factsheetVerticalTableScripts } from './factsheet-vertical-table/FactsheetVerticalTable';
import { factsheetPieChartScripts } from './factsheet-pie-chart/FactsheetPieChart';
import { priceDistributionScripts } from './price-distribution/PriceDistribution';
import { factSheetFundHeaderScript } from './factsheet-fund-header/FactSheetFundHeader';
import { horizontalTableScript } from './horizontal-tables/HorizontalTables';
import { factsheetSectionScripts } from './factsheet-section/FactsheetSection';
import { factsheetOverviewScripts } from './factsheet-overview/FactsheetOverview';
import { factsheetHorizontalTextScripts } from './factsheet-horizontal-text/FactsheetHorizontalText';
import { buttonToggleGroupScripts } from './button-toggle-group/ButtonToggleGroup';
import { attributionScripts } from './attribution/Attribution';
import { interactiveMapScripts } from './interactive-map/InteractiveMap';
import { smoothScrollAnchorLinkScripts } from './smooth-scroll-anchor-link/smooth-scroll-anchor-link';
import { retirementPreparednessScripts } from './retirement-preparedness/RetirementPreparedness'
import { formBannerScripts } from './form-banner/FormBanner';
import { milestonesScripts } from './milestones/Milestones';
import { formSelectScripts} from './form-select/formSelect';
import accordionDrawerScripts from './accordion-drawer/AccordionDrawer';
import accordionToggleListScripts from "./accordion-toggle-list/AccordionToggleList";
import { backButtonScripts } from './back-button/BackButton';

import alertScripts from './alert/Alert';

window.addEventListener('load', () => {
  siteAnimations.loadScripts();
  headerScripts.loadScripts();
  headerMobileScripts.loadScripts();
  toggleButtonScripts.loadScripts();
  FAQScripts.loadScripts();
  headerLoginScripts.loadScripts();
  customSelectScripts.loadScripts();
  CollapseListScripts.loadScripts();
  contentSearchScripts();
  siteSearchScripts();
  tagFilterScripts();
  cardGroupScripts();
  toggleRadioScripts.loadScripts();
  carouselScripts.loadScripts();
  initializeContentSearch();
  dateTagFilterScripts();
  wizardScripts.loadScripts();
  interactiveMapScripts.loadScripts();
  factsheetVerticalTableScripts.loadScripts();
  factsheetPieChartScripts.loadScripts();
  fundsListingScripts.loadScripts(null, null, null, null);
  priceDistributionScripts.loadScripts(null, null);
  factSheetFundHeaderScript.loadScripts();
  horizontalTableScript.loadScripts();
  factsheetSectionScripts.loadScripts();
  factsheetOverviewScripts.loadScripts();
  factsheetHorizontalTextScripts.loadScripts();
  buttonToggleGroupScripts.loadScripts();
  attributionScripts.loadScripts();
  alertScripts.loadScripts();
  smoothScrollAnchorLinkScripts.loadScripts();
  retirementPreparednessScripts.loadScripts();
  milestonesScripts.loadScripts();
  formBannerScripts();
  accordionDrawerScripts.loadScripts();
  accordionToggleListScripts.loadScripts();
  formSelectScripts();
  backButtonScripts();
});
