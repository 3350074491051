////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  09/12/2023    BBARRON       113813        Initial create
//  09/20/2023    BBARRON       114362        Only load retirement preparedness tool once from data attribute
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const retirementPreparednessScripts = {
    loadScripts: () => {

        const iframeId = 'vccalcfrm';
        
        /**
         * Ensure that the message sent to this browser window pertains to the retirement preparedness tool
         * @param {*} evt The PostMessage event
         * @returns True if the iframe is on the page and the message origin matches the iframe src
         */
        const validateEventOrigin = (evt) => {
            const iframe = document.getElementById(iframeId);
            if (!iframe) {
                return false;
            }

            const iframeSrc = iframe.src.toLowerCase();
            const eventOrigin = evt.origin.toLowerCase();

            if (!iframeSrc.includes(eventOrigin)) {
                return false;
            }

            return true;
        }

        /**
         * Handle PostMessage event sent from iframe to current browser window. Only respond to resize messages
         * @param {*} evt 
         */
        const handleIframeMessage = (evt) => {
            if (!validateEventOrigin(evt)) {
                return;
            }
    
            var msg = evt.data.message || 'unknown msg'
            if (msg === 'resizeht') {
                resizeIframe(evt.data.ht - 0);
            }
        }

        /**
         * Resizes the iframe to the given new height
         * @param {number} newHeight The new iframe height
         */
        const resizeIframe = (newHeight) => {
            var iframe = document.getElementById(iframeId);
            iframe.style.height = newHeight + 'px';
        }

        // Start listening for iframe messages early
        window.addEventListener('message', handleIframeMessage, false);
        
        var iframe = document.getElementById(iframeId);
        if (iframe) {
            var iframeUrl = iframe.dataset.url;
            iframe.src = iframeUrl;
        }
    }
};