// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   10/16/2020    JJacob        49024          Initial Create
//   11/11/2020    JJacob        52012          Changed element IDs to operate dynamically
//   03/10/2021    KKapoor       56955          Check for missing sub header
//   08/19/2022    KKapoor       89884          Close desktop nav menu when a link is clicked
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const headerScripts = {
  loadScripts: () => {
    const handleLinkClick = link => {
      const elems = document.getElementsByClassName('gs-subheader');
      const parentElems = document.getElementsByClassName('gs-header-link');
      [...elems].forEach(element => {
        if (element.id === 'gs_subheader_' + link) {
          element.classList.add('--active');
        } else {
          element.classList.remove('--active');
        }
      });

      // color the header
      [...parentElems].forEach(element => {
        if (element.id === 'gs_header_link_' + link) {
          element.classList.add('gs-header-link--opened');
        } else {
          element.classList.remove('gs-header-link--opened');
        }
      });
    };

    const closeAllMenus = () => {
      const elems = document.getElementsByClassName('gs-subheader');
      const subheaderPattern = new RegExp('^gs_subheader_(.*)$');
      [...elems].forEach(subheader => {
        const match = subheader.id.match(subheaderPattern);
        if(!match || match.length < 2) { return; }
        const link = match[1];
        handleLinkExit(link);
      })
    }

    const handleLinkExit = link => {
      document.getElementById('gs_subheader_' + link).classList.remove('--active');
      let headerLink = document.getElementById('gs_header_link_' + link);
      headerLink.classList.remove('gs-header-link--opened');
    };

    const linkNumber = 5;
    const headerID = 'gs_header_link_';
    const subheaderID = 'gs_subheader_';

    if (document.getElementsByClassName('gs-header-link').length > 0) {
      for (let index = 0; index < linkNumber; index++) {
        const header = document.getElementById(headerID + index);
        if (header) {
          header.addEventListener('mouseover', () => {
            handleLinkClick(index);
          });
        }

        const subHeader = document.getElementById(subheaderID + index);
        if (subHeader) {
          subHeader.addEventListener('mouseleave', () => {
            handleLinkExit(index);
          });
        }

        const loginLink = document.getElementById('gs_header_login_link');
        if (loginLink) {
          loginLink.addEventListener('mouseover', () => {
            handleLinkExit(index);
          });
        }
      }

      // Close nav menu when a link is clicked
      let headerLinks = document.getElementsByClassName('gs-subheader-link');
      [...headerLinks].forEach(link => {
        link.addEventListener('click', closeAllMenus, false);
      })
    }
  }
};
