////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  06/16/2022    BBARRON       86867         Initial create
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const factsheetHorizontalTextScripts = {
    loadScripts: () => {

        /**
         * Handles a click on the expand button, changing the state of the component to expanded
         * @param {ClickEvent} evt 
         */
        const handleExpandClick = (evt) => {
            evt.preventDefault();
            let parent = evt.currentTarget.parentNode;
            if(parent.classList.contains('factsheet-htext-body')) {
                parent.classList.remove('factsheet-htext-body--collapsed');
                parent.classList.add('factsheet-htext-body--expanded');
            }
          };
    

          /**
           * Handles a click on the collapse button, changing the state of the component to collapsed
           * @param {ClickEvent} evt 
           */
          const handleCollapseClick = (evt) => {
            evt.preventDefault();
            let parent = evt.currentTarget.parentNode;
            if(parent.classList.contains('factsheet-htext-body')) {
                parent.classList.add('factsheet-htext-body--collapsed');
                parent.classList.remove('factsheet-htext-body--expanded');
            }
          };

          
          // Get all expand buttons and have them listen for click events
          const expandButtons = document.querySelectorAll('.factsheet-htext-controls--collapsed');
          expandButtons.forEach(button => {
            button.addEventListener('click', handleExpandClick, false);
          });
    
          // Get all collapse buttons and have them listen for click events
          const collapseButtons = document.querySelectorAll('.factsheet-htext-controls--expanded');
          collapseButtons.forEach(button => {
            button.addEventListener('click', handleCollapseClick, false);
          });
    
          const expandCollapseThreshold = 180;
    
          /**
           * Checks the text height and only shows the expand-collapse
           * options if height is greater than the expandCollapseThreshold.
           */
          function resetCollapsing() {
            const factsheetBodies = document.querySelectorAll('.factsheet-htext-body');
            factsheetBodies.forEach(body => {
              if(body.offsetHeight > expandCollapseThreshold) {
                body.classList.add('factsheet-htext-body--collapsed');
              } else {
                body.classList.remove('factsheet-htext-body--collapsed');
                body.classList.remove('factsheet-htext-body--expanded');
              }
            });
          }
    
          resetCollapsing();
    
          /**
           * Re-check whether to show/hide expand-collapse buttons on browser resize
           */
          window.addEventListener('resize', function() {
            this.window.requestAnimationFrame(resetCollapsing);
          });
    }
};