// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   11/02/2020    JJacob        49830           Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const FAQScripts = {
  loadScripts: () => {
    const handleClick = id => {
      const active = document.querySelector('.gs-faq-card.--active');
      let activeId;
      if (active) {
        activeId = active.id;
        active.classList.remove('--active');
      }
      if (activeId !== id) {
        const selected = document.querySelector('#' + id);
        selected.classList.add('--active');
      }
    };

    const elems = document.querySelectorAll('.gs-faq-card');
    elems.forEach(element => {
      element.addEventListener('click', () => handleClick(element.id));
    });
    const stopLink = document.querySelectorAll('.gs-faq-card a');
    stopLink.forEach(element => {
      element.addEventListener('click', e => {
        e.stopPropagation();
      });
    });
  }
};
