////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  04/05/2022    BBARRON       83017         Initial create
//  08/16/2022    BBARRON       90362         Fix timestamp parsing. Timestamps were being interpreted as local dates
//                                            rather than UTC dates, throwing off inception dates by one day.
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Filter function that ensures all elements are distinct (no duplicates)
 * @param {*} value The value to check
 * @param {*} index The value's index
 * @param {*} self  The full list of values
 * @returns 
 */
 export const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
};

export const formatNumber = (number) => {
    if (!number && number != "0") {
        return "N/A";
    }

    return number.toFixed(2) + "%";
};

export const formatAssetValue = (number) => {
    if (!number) {
        return "N/A";
    }

    return number.toFixed(2);
};

/**
 * Timestamps are interpreted as local time by default.
 * This assumes the timestamp is for a UTC date instead which is what comes from the fund performance API
 * @param {number} milliseconds The UTC timestamp
 */
export const parseUtcTimestamp = (milliseconds) => {
    // Incorrectly parse the timestamp as if it was local time to get a rough estimate of the date/time.
    let localTime = new Date(milliseconds);
    // Timezone offset changes throughout the year. Get the specific timezeone offset at the approximate time of the timestamp
    let utcOffset = localTime.getTimezoneOffset() * 60000;
    // Add the time zone offset to convert it to UTC
    return new Date(milliseconds + utcOffset);
}

export const formatTimestamp = (date) => {
    let pattern = new RegExp(/Date\((\d+)\)/);
    let matches = pattern.exec(date);
    if (!matches || matches.length < 1) {
        return "";
    }

    let milliseconds = parseInt(matches[1]);
    let parsedDate = parseUtcTimestamp(milliseconds);
    return formatDate(parsedDate);
}

export const formatDate = (date) => {
    let parsedDate = new Date(date);
    let dd = String(parsedDate.getDate()).padStart(2, '0');
    let mm = String(parsedDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = parsedDate.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
};

export const formatMorningstar = (number, siblingNumber) => {
    if (!number || !siblingNumber) {
        return "N/A";
    }

    return number;
};