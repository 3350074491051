////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  11/02/2020    JJacob        49830           Initial Create
//  02/28/2023    BBARRON       101078          Moved Sitecore script into this script, 
//                                              Only enable next button on card click, not all buttons on page
//                                              Change dataset.wizard to dataset.action
//  03/01/2023    BBARRON       101001          Simplify scrpt now that next/prevous buttons are removed
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const wizardScripts = {
  loadScripts: () => {

    
    /**
     * Trigger an analytics event before taking user to their desired page
     * @param {*} evt 
     */
    const handleCardClick = evt => {
      const card = evt.currentTarget;
      if (!card.href) {
        evt.preventDefault();
        return;
      }

      const action = card.dataset.action;
      if (!!action && window.dataLayer) {
        // Trigger the google analytics event
        window.dataLayer.push({ 'wizardName': action, 'wizardUrl': card.href, 'event': 'wizardLaunch' });
      }
    };


    // Wire up the card and next button click events
    if (document.getElementById('gs_wizard')) {
      const cards = document.querySelectorAll('.gs-wizard-content-card');
      cards.forEach(card => {
        card.addEventListener('click', handleCardClick);
      });
    }
  }
};