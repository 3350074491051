////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  10/23/23      GCASEY        117077          Initial Create
//  10/31/2023    GCASEY        117782          Fix console errors
//  11/13/2023    BBARRON       118664          Limit scope of script to elements within a given container.
//  02/12/2024    GCASEY        122529          Fix bug where slider went outside of container
//  02/13/2024    GCASEY        122529          Fix slider not animating bug
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const loadScripts = () => {
    const containers = Array.from(document.getElementsByClassName('gs-accordiontogglelist'));
    
    const selectedClass = "--selected";
    const sliderTransition = "all .5s ease-in-out";
    // offset to hide divider border lines
    const sliderWidthOffset = 6;


    // Toggling between accordion menus should not impact anything outside of the given container
    containers.forEach(container => {
        const buttons = Array.from(container.getElementsByClassName("gs-accordiontogglelist-picker-button"));
        const slider = container.getElementsByClassName("gs-accordiontogglelist-picker-slider")[0];
        const accordionPicker = container.getElementsByClassName("gs-accordiontogglelist-picker")[0];
        const drawerSections = Array.from(container.getElementsByClassName("gs-accordiontogglelist-drawer-section"));

        if(!accordionPicker) {
            return;
        }

        // Removes the selected class from all picker buttons
        const removeAllButtonSelections = () => {
            buttons.forEach((button) => {
                button.classList.remove(selectedClass);
            });
        }

        // Removes the selected class from all drawer groups
        const removeAllDrawerSelections = () => {
            drawerSections.flatMap(section => Array.from(section.childNodes))
                .forEach((drawer) => drawer.classList.remove(selectedClass));
        }

        // Moves the position of the slider for the button click transition
        const moveSliderPosition = (selectedButton) => {
            const selectedButtonX = selectedButton.getBoundingClientRect().x;
            const accordionPickerX = accordionPicker.getBoundingClientRect().x;
            const xTranslation = selectedButtonX - accordionPickerX;

            if(accordionPickerX !== 0 && selectedButtonX !== 0) {
                slider.style.transform = `translateX(${xTranslation}px)`;
            }
        }

        // Adjusts slider position and sets drawer group visibility
        const pickerButtonClicked = (e) => {
            const button = e.target.closest(".gs-accordiontogglelist-picker-button");
            removeAllButtonSelections();
            moveSliderPosition(button);

            button.classList.add(selectedClass);

            const buttonIndex = buttons.indexOf(button);
            drawerSections.forEach((drawer) => drawer.classList.remove("--active"));
            drawerSections[buttonIndex].classList.add("--active");
            removeAllDrawerSelections();
        }

        // Recalculates slider translation value for when resized
        const fixSliderPositionOnResize = () => {
            const selectedButton = document.querySelectorAll("div.gs-accordiontogglelist-picker-button.--selected")[0];
            moveSliderPosition(selectedButton);
        }

        // Sets width of the slider no matter how many picker buttons there are 
        const setSliderWidth = () => {
            slider.style.transition = "none !important";
            slider.style.width = `calc(${1 / buttons.length * 100 }% + ${sliderWidthOffset}px)`;
            slider.style.transition = sliderTransition;
        }

        buttons.forEach((button) => button.addEventListener("click", pickerButtonClicked));
        new ResizeObserver(fixSliderPositionOnResize).observe(document.body);
        setSliderWidth();
    });
}

export default { loadScripts };