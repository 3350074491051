/* eslint-disable spaced-comment */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  10/16/2020    JJacob        49024          Initial Create
//  11/11/2020    JJacob        52012          Changed element IDs to operate dynamically
//  03/10/2021    KKapoor       56955          Check for missing sub header
//  08/19/2022    KKapoor       89884          Close desktop nav menu when a link is clicked
//  01/31/2025    BBARRON       147759         Renamed a number of variables for clarity, added documentation
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const headerScripts = {
  loadScripts: () => {
    /**
     * Reveals the desired subnav menu and hides the others marks the desired
     * header link as opened and marks the others as closed
     * @param {number} index The index of the header navigation link
     */
    const handleLinkClick = index => {
      const subheaders = document.getElementsByClassName('gs-subheader');
      const parentElems = document.getElementsByClassName('gs-header-link');
      [...subheaders].forEach(subheader => {
        if (subheader.id === 'gs_subheader_' + index) {
          subheader.classList.add('--active');
        } else {
          subheader.classList.remove('--active');
        }
      });

      // color the header
      [...parentElems].forEach(headerLink => {
        if (headerLink.id === 'gs_header_link_' + index) {
          headerLink.classList.add('gs-header-link--opened');
        } else {
          headerLink.classList.remove('gs-header-link--opened');
        }
      });
    };

    /**
     * Close all header menus
     */
    const closeAllMenus = () => {
      const subheaders = document.getElementsByClassName('gs-subheader');
      const subheaderPattern = new RegExp('^gs_subheader_(.*)$');
      [...subheaders].forEach(subheader => {
        const match = subheader.id.match(subheaderPattern);
        if (!match || match.length < 2) {
          return;
        }
        const link = match[1];
        handleLinkExit(link);
      });
    };

    /**
     * Ensures that the given navigation header menu gets closed
     * @param {number} index The index of the header navigation menu that is closed
     */
    const handleLinkExit = index => {
      document.getElementById('gs_subheader_' + index).classList.remove('--active');
      const headerLink = document.getElementById('gs_header_link_' + index);
      headerLink.classList.remove('gs-header-link--opened');
    };

    const linkNumber = 5;
    const headerID = 'gs_header_link_';
    const subheaderID = 'gs_subheader_';

    if (document.getElementsByClassName('gs-header-link').length > 0) {
      for (let index = 0; index < linkNumber; index++) {
        const header = document.getElementById(headerID + index);
        if (header) {
          header.addEventListener('mouseover', () => {
            handleLinkClick(index);
          });
        }

        const subHeader = document.getElementById(subheaderID + index);
        if (subHeader) {
          subHeader.addEventListener('mouseleave', () => {
            handleLinkExit(index);
          });
        }

        const loginLink = document.getElementById('gs_header_login_link');
        if (loginLink) {
          loginLink.addEventListener('mouseover', () => {
            handleLinkExit(index);
          });
        }
      }

      // Close nav menu when a link is clicked
      const headerLinks = document.getElementsByClassName('gs-subheader-link');
      [...headerLinks].forEach(link => {
        link.addEventListener('click', closeAllMenus, false);
      });
    }
  }
};
