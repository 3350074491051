////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  07/19/2022    BBARRON       88352           Initial Create
//  08/02/2023    BBARRON       110687          Renamed file. Automatically account for sticky page header when scrolling, allow scollingto be instant or smooth
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * This utility allows you to detect the anchor tag in the url and jump the user down the page to that anchor tag.
 * A browser will do this for you if the element exists when the DOM is loaded. This script is useful when the element does not exist when the DOM is loaded.
 * For example if you have dynamically loaded content
 */

/**
 * Gets the x and y position of an element
 * @param {DomElement} el 
 * @returns 
 */
 const getOffset = (el) => {
    const rect = el.getBoundingClientRect();

    return {
        left: rect.left,
        top: rect.top
    };
};

const desktopHeaderHeight = 78;
const mobileHeaderHeght = 69;
const largestMobileBreakpoint = 1023;

const defaultScrollOptions = Object.freeze({
    /**
    * Determines how far above (-) or below (+) the target element you want to end up after scrolling. This is an additional manual offset beyond what autoOffset does
    * You can enter a number that will be used for both desktop and mobile or you can use an object {desktop: -5, mobile: -12} if you need different values by device
    * This is not meant to account for the sticky page header
    **/
    manualOffset: {
        desktop: 0, 
        mobile: 0
    },
    /**
     * Attempts to auto-correct for the sticky page header, will adjust the scroll position for desktop and mobile accordingly
     */
    correctForStickyHeader: true,
    /**
     * behavior can be 'smooth', 'instant', or 'auto'. If set to auto it will be based on the css property 'scroll-behavior'
     */
    behavior: 'instant'
});

/**
 * Determines whether the page header is in desktop configuration
 * @returns true if page header is in a desktop configuration, false if mobile
 */
const isDesktop = () => {
    return window.innerWidth > largestMobileBreakpoint;
}

/**
 * Determines the current height of the sticky page header
 * @returns the sticky header height
 */
const getHeaderHeight = () => {
    return isDesktop() ? desktopHeaderHeight : mobileHeaderHeght;
};

/**
 * Interprets the manualOffset setting based on type and current device size
 * @param {*} manualOffset Any manual adjustments that are needed. @see {@link defaultScrollOptions.manualOffset} for more information
 * @returns A single value to be used for adjusting the final scroll position
 */
const getCurrentManualOffset = (manualOffset) => {
    if (!manualOffset) {
        return 0;
    }

    switch (typeof(manualOffset)) {
        case 'number':
            return manualOffset;
        case 'string':
            return parseInt(manualOffset);
        case 'object':
            if(isDesktop() && manualOffset.hasOwnProperty('desktop')) {
                return manualOffset.desktop;
            }

            if (!isDesktop() && manualOffset.hasOwnProperty('mobile')) {
                return manualOffset.mobile;
            }
        default:
            return 0;
    }
}

/**
 * Gets the final scroll position
 * @param {object} elementOffset The { left, top } position of the element relative to the top-left of the page
 * @param {object|number} manualOffset Any manual adjustments that are needed. @see {@link defaultScrollOptions.manualOffset} for more information
 * @param {boolean} correctForStickyHeader Whether to account for sticky page header in offset calculations @see {@link defaultScrollOptions.correctForStickyHeader} for more information
 * @returns The final {left, top} scroll position. Where we want to end up after the scroll operation.
 */
const getTotalOffset = (elementOffset, manualOffset, correctForStickyHeader) => {

    let totalOffset = {
        left: elementOffset.left, 
        top: elementOffset.top + getCurrentManualOffset(manualOffset)
    };

    if (correctForStickyHeader) {
        totalOffset.top -= getHeaderHeight();
    }

    return totalOffset;
}

/**
 * Gets the anchor link id from the url
 * @param {string} url The url to get the anchor tag from. Defaults to browser url
 */
export const getFragmentFromUrl = (url) => {
    if(!url) {
        url = window.location.href;
    }
    const position = url.indexOf("#");
    if(position === -1 || position === url.length - 1) {
        return null;
    }
    var hash = url.substring(url.indexOf("#")+1);
    return hash;
};

/**
 * Jumps the user down the page to the position of the given element
 * @param {DomElement} element The element to jump to
 * @param {object} options The scroll options. @see {@link defaultScrollOptions} for more information
 * @returns 
 */
 export const scrollToElement = (element, options) => {
    let fullOptions = Object.assign({}, defaultScrollOptions, options || {});

    // Get element's position relative to current scroll position
    const elementOffset = getOffset(element);

    // Adjust offset based on sticky header height and any manual adjustments desired
    const finalOptions = getTotalOffset(elementOffset, fullOptions.manualOffset, fullOptions.correctForStickyHeader);
    finalOptions.behavior = fullOptions.behavior;
    window.scrollBy(finalOptions);
};

/**
 * Jumps the user down the page to the position of the element with the given id
 * @param {string} id The id of the element to jump to
 * @param {object} options The scroll options. @see {@link defaultScrollOptions} for more information
 * @returns 
 */
export const scrollToId = (id, options) => {
    const element = document.getElementById(id);
    if(!element) {
        return;
    }
    scrollToElement(element, options);
};

/**
 * Jumps the user to the anchor tag indicated by the given url
 * @param {string} url The url to get the anchor tag from. Defaults to browser url
 * @param {object} options The scroll options. @see {@link defaultScrollOptions} for more information
 */
 export const scrollToAnchorInUrl = (url, options)=> {
    const anchorId = getFragmentFromUrl(url);
    if(!anchorId) {
        return;
    }
    scrollToId(anchorId, options);
};