////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  11/02/2020    JJacob        49830           Initial Create
//  03/28/2022    BBARRON       82090           Refactored for readability
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const siteAnimations = {
  loadScripts: () => {

    // All the elements to be animated
    let elements;

    // The height of the viewport
    let viewportHeight;

    /**
     * Determines whether the top of the given element is further down the page than the bottom of the viewport
     * @param {*} el The element to be animated
     * @returns True if element is not below the viewport
     */
     function isBelowViewport(el) {
      var elementYCoordinate = el.getBoundingClientRect().top; // Element's y coordinate relative to viewport top. (Lower position on screen is higher y coordinate)
      return elementYCoordinate > viewportHeight;
    } 

    /**
     * Activates the animation if not yet activated
     * @param {*} el The element to be animated
     */
    function activateAnimation(el) {
      if(el.classList.contains('gs-animate-init')) {
        el.classList.add('gs-animateIn-bottom');
        el.classList.remove('gs-animate-init');
      }
    }

    /**
     * Checks the position of all elements to be animated and 
     * activates the animation if they have scrolled into view
     */
     function checkPosition() {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (!isBelowViewport(element)) {
          activateAnimation(element);
        }
      }
    }

    /**
     * Initialize animated elements
     */
    function init() {
      viewportHeight = window.innerHeight;
      elements = document.querySelectorAll('.gs-container-inner');
      elements.forEach(element => {
        if (!element.classList.contains('gs-animate-false') && isBelowViewport(element)) {
          element.classList.add('gs-animate-init');
        }
      });
      checkPosition();
    }

    window.addEventListener('scroll', checkPosition);
    window.addEventListener('click', checkPosition);
    window.addEventListener('resize', init);

    init();
  }
};
