////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  05/11/2022    ZSunOo        77752           Initial Create
//  09/01/2022    BBARRON       89603           Pulled lower table rendering out into its own function so it can be rerendered independently of the chart
//  09/12/2022    BBARRON       89878           Remove unnecessary console logging, update width on chart re-render
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import * as d3 from 'd3';
import d3 from 'd3';
import moment from 'moment';
import { elements } from './elements';

// something in another lib is overridding this. Storing a reference for later use...hopefully
// it does the trick
const _d3Mouse = d3.mouse;
const _d3Zoom = d3.behavior.zoom();

function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  return formatter.format(amount);
}

/**
 * Re-renders the historical prices table at the bottom of the chart. Filtered out rows are not just hidden, 
 * they are not rendered at all so css nth-child()-based row coloring works.
 * @param {string} filter (nav|div|cap|all)
 * @param {object[]} tableRows A list of data rows with the format {date: YYYY-MM-ddThh:mm:ss.000Z, value: XX.XX, class: "hist-NAV"}
 * @returns void
 */
function renderHistoricalPricesTable(filter, tableRows) {
  const historicalPricesTable = document.querySelector(elements.historicalPricesTable);
  if (!historicalPricesTable) {
    return;
  }

  historicalPricesTable.innerHTML = '';

  if (!filter) {
    filter = "all";
  }

  if (!tableRows) {
    tableRows = JSON.parse(historicalPricesTable.dataset.tableRows);
  } else {
    historicalPricesTable.dataset.tableRows = JSON.stringify(tableRows);
  }

  let template = '';

  tableRows.filter((item) => {
    if (filter === "nav") { return item.class === elements.histNav.substring(1)}
    if (filter === "div") { return item.class === elements.histDiv.substring(1)}
    if (filter === "cap") { return item.class === elements.histCap.substring(1)}
    return true;
  }).forEach(function (item, index) {
    const itemMarkup = `<tr class="data-type-daily ${item.class}"><td style="width:25%">${moment
      .utc(item.date)
      .format('MM/DD/YYYY')}</td><td style="width:25%">${
        item.value === '' ? '' : formatCurrency(item.value)
      }</td><td>${item.description}</td></tr>`;
    template    += itemMarkup;
  });

  historicalPricesTable.innerHTML = template;
  
}

/**
 * This class helps with rendering the Historical prices and distributions chart.
 * It takes data that has been pulled from the API, but the data must be processed first
 */
class LineGraph {
  constructor(args) {
    this._container = d3.select(args.container);
    this._node = args.container;
    this._distributionCheckbox = args.distributionCheckbox;
    this._data = args.data;
    this._assetValues = args.data.values;
    this._distributions = args.data.distributions;
    this._height = 200;
    this._width = this._node.offsetWidth;
    this._svg = this._container.append('svg');
    this._labelContainer = this._svg.append('g').attr('class', 'label-container');
    this._marginContainer = this._svg.append('g').attr('class', 'margin-container');
    this._margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const that = this;

    this._distributionCheckbox.addEventListener('change', () => {
      if (this._distributionCheckbox.checked) {
        that._showDistributions();
      } else {
        that._removeDistributions();
      }
    });
  }

  _setData(data) {
    this._data = data;
    this._assetValues = data.values;
    this._distributions = data.distributions;
  }

  /**
   * Update the chart data and re-render
   * @param {object} data The processed chart data
   */
  update(data) {
    this._setData(data);
    this.reRender();
  }

  /**
   * Clear the chart and render it again using same data
   */
  reRender() {
    this._container.select('svg').remove();
    this._svg = this._container.append('svg');
    this._width = this._node.offsetWidth;
    this._labelContainer = this._svg.append('g').attr('class', 'label-container');
    this._marginContainer = this._svg.append('g').attr('class', 'margin-container');
    this.render();
  }

  _formatCurrency(amount) {
    return formatCurrency(amount);
  }

  /**
   * Render the chart without changing the data
   */
  render() {
    const that = this;
    this._marginContainer.attr('transform', 'translate(' + this._margin.left + ',' + this._margin.top + ')');

    this._tooltip = d3.select('.chart-container').append('div').attr('class', 'tooltip').style('opacity', 0);

    this._currentValueTooltip = d3
      .select('.chart-container')
      .append('div')
      .attr('class', 'current-value-tooltip')
      .style('opacity', 0);

    const startDate = this._assetValues[0].date,
      endDate = this._assetValues[this._assetValues.length - 1].date;

    //does this break IE?
    const allValues = this._assetValues.map(function (i) {
      return i.value;
    });

    this._x = d3.time.scale().range([0, this._width]);
    this._y = d3.scale.linear().range([this._height, 0]);

    this._x.domain([startDate, endDate]);
    this._y.domain([d3.min(allValues), d3.max(allValues)]);

    const area = d3.svg
      .area()
      .x(function (d) {
        return that._x(d.date);
      })
      .y0(this._height)
      .y1(function (d) {
        return that._y(d.value);
      });

    const line = d3.svg
      .area()
      .x(function (d) {
        return that._x(d.date);
      })
      .y(function (d) {
        return that._y(d.value);
      });

    const xAxis = d3.svg
      .axis()
      .scale(this._x)
      .tickSize(0)
      .tickFormat(d3.time.format('%m/%d/%Y'))
      .orient('bottom')
      .ticks(5);

    const yAxis = d3.svg.axis().scale(this._y).tickSize(2).orient('left').tickFormat(d3.format('$2.2f')).ticks(6);

    const zoomed = function () {
      const t = zoom.translate();
      const s = zoom.scale();

      let tx = t[0];
      const ty = t[1];
      tx = Math.min(0, Math.max(that._width * (1 - s), t[0]));

      zoom.translate([tx, ty]);
      that._svg.select('g.x').call(xAxis);

      that._svg
        .selectAll('circle.circle')
        .attr('r', 0)
        .attr('cx', function (d) {
          return that._x(d.date);
        })
        .attr('cy', function (d) {
          return that._y(d.value);
        })
        .attr('r', 4);

      that._svg
        .select('path.line')
        .attr('clip-path', 'url(#clipper)')
        .datum(that._assetValues)
        .attr('class', 'line')
        .attr('d', line);

      that._svg.select('path.area').datum(that._assetValues).attr('d', area);

      if (that._distributionCheckbox.checked) {
        that._removeDistributions();
        that._showDistributions();
      }
    };

    const zoom = _d3Zoom.x(this._x).scaleExtent([1, 5]);

    zoom.on('zoom', zoomed);

    this._svg.attr('width', '100%').attr('height', this._height + this._margin.top + this._margin.bottom);

    this._svg.call(zoom);

    const defs = this._svg.append('svg').attr('width', 0).attr('height', 0).append('defs');

    defs
      .append('clipPath')
      .attr('id', 'clipper')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this._width)
      .attr('height', this._height);

    this._marginContainer
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + this._height + ')')
      .call(xAxis);

    this._marginContainer.append('g').attr('class', 'y axis').call(yAxis).append('text');

    this._labelContainer.attr('transform', 'translate(' + (this._margin.left + 20) + ',' + this._margin.top + ')');

    this._marginContainer
      .append('path')
      .datum(this._assetValues)
      .attr('clip-path', 'url(#clipper)')
      .attr('class', 'line')
      .attr('d', line);

    this._marginContainer
      .append('path')
      .datum(this._assetValues)
      .attr('clip-path', 'url(#clipper)')
      .attr('class', 'area')
      .attr('d', area);

    const mouseG = this._marginContainer.append('g').attr('class', 'mouse-over-effects').attr('height', this._height);

    mouseG.append('circle').attr('class', 'current-value-circle').attr('r', 5).style('opacity', 0);

    mouseG
      .append('svg:rect')
      .attr('width', this._width)
      .attr('height', this._height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all');

    mouseG
      .on('mousemove', function () {
        const mouse = _d3Mouse(this);
        const xDate = that._x.invert(mouse[0]);
        const bisect = d3.bisector(function (d) {
          return d.date;
        }).right;
        const item = that._assetValues[bisect(that._assetValues, xDate)];

        d3.select('.current-value-circle')
          .datum(item)
          .style('opacity', 1)
          .attr('cx', function (d) {
            return that._x(d.date);
          })
          .attr('cy', function (d) {
            return that._y(d.value);
          });

        that._currentValueTooltip
          .html(that._formatCurrency(item.value) + '<br/>' + moment.utc(item.date).format('MM/DD/YYYY'))
          .style('opacity', 1)
          .style('left', that._x(item.date) + 50 + 'px')
          .style('top', that._y(item.value) + 160 + 'px');
      })
      .on('mouseout', function () {
        that._currentValueTooltip.transition().duration(500).style('opacity', 0);

        d3.select('.current-value-circle').transition().duration(500).style('opacity', 0);
      });

    if (this._distributionCheckbox?.checked) {
      this._showDistributions();
    }

    this._renderTable();
  }


  _renderTable() {
    const historyTableSelect = document.querySelector(elements.historyTableSelect);
    if (historyTableSelect) {
      historyTableSelect.value = 'all';
    }

    const tableRows = [];
    const that = this;

    this._distributions.forEach(function (item) {
      if (typeof item.value !== 'undefined') {
        let desc = '';
        let distclass = '';
        if (item.type === 0) {
          desc = 'Pay / Reinvest Date - Dividends';
          distclass = 'hist-DIV';

          tableRows.push({
            date: moment.utc(item.dateRecord).format('MM/DD/YYYY'),
            value: '',
            description: 'Record Date - Dividends',
            class: 'hist-DIV'
          });
        } else if (item.type === 1) {
          desc = 'Pay / Reinvest Date - Long Term Capital Gain';
          distclass = 'hist-CAP';

          tableRows.push({
            date: moment.utc(item.dateRecord).format('MM/DD/YYYY'),
            value: '',
            description: 'Record Date - Long Term Capital Gain',
            class: 'hist-CAP'
          });
        } else if (item.type === 2) {
          desc = 'Pay / Reinvest Date - Short Term Capital Gain';
          distclass = 'hist-CAP';

          tableRows.push({
            date: moment.utc(item.dateRecord).format('MM/DD/YYYY'),
            value: '',
            description: 'Record Date - Short Term Capital Gain',
            class: 'hist-CAP'
          });
        }

        tableRows.push({
          date: moment.utc(item.date).format('MM/DD/YYYY'),
          value: item.rate,
          description: desc,
          class: distclass
        });
      }
    });

    this._assetValues.forEach(function (v) {
      tableRows.push({
        date: v.date,
        value: v.value,
        description: 'NAV',
        class: 'hist-NAV'
      });
    });

    tableRows.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    renderHistoricalPricesTable("all", tableRows);
  }

  _showDistributions() {
    const container = this._marginContainer;
    const circleContainer = container.append('g').attr('class', 'circles');

    const that = this;

    this._distributions.forEach(function (item) {
      if (typeof item.value !== 'undefined') {
        const label = `Value: ${that._formatCurrency(item.value)}<br/>Dividend: ${that._formatCurrency(
          item.rate
        )}<br/>${moment.utc(item.date).format('MM/DD/YYYY')}`;
        const data = {
          date: item.date,
          value: item.value,
          rate: item.rate,
          label: label
        };
        that._renderDistribution(circleContainer, data);
      }
    });
  }

  _removeDistributions() {
    const container = this._marginContainer;
    container.selectAll('.circles .distribution-circle').remove();
  }

  _renderDistribution(container, data) {
    const that = this;
    container
      .datum(data)
      .append('circle')
      .attr('clip-path', 'url(#clipper)')
      .attr('class', 'distribution-circle')
      .attr('cx', function (d) {
        return that._x(d.date);
      })
      .attr('cy', function (d) {
        return that._y(d.value);
      })
      .attr('r', 5)
      .on('mouseover', function (d) {
        that._tooltip.transition().duration(200).style('opacity', 0.9);
        that._tooltip
          .html(d.label)
          .style('left', that._x(d.date) + 50 + 'px')
          .style('top', that._y(d.value) + 20 + 'px');
      })
      .on('mouseout', function () {
        that._tooltip.transition().duration(500).style('opacity', 0);
      });
  }
}

export {
  LineGraph,
  renderHistoricalPricesTable
}