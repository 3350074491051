// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   05/11/2022    ZSunOo        77752           Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const elements = {
  chartContainer: '.chart-container',
  factsheetSectionHeading: '.gs-factsheet-section-heading',
  historicalPricesContainer: '.historical-prices-container',
  historicalPricesContainerInner: '.historical-prices-container .component-container',
  historicalPricesContainerTable: '.historical-prices-container-table',
  historicalFromDate: '#historical-from-date',
  historicalToDate: '#historical-to-date',
  historicalPricesTable: '.historical-prices-table',
  updateHistoryButton: '.update-history-button',
  showHistoryTable: '.showhistorytable',
  downloadExcelButton: '.download-excel-button',
  downloadCsvButton: '.download-csv-button',
  historyTableSelect: '#historytableselect',
  dataTypeDaily: '.data-type-daily',
  histNav: '.hist-NAV',
  histDiv: '.hist-DIV',
  histCap: '.hist-CAP',
  distributionCheckbox: 'input[name="showdistributions"]',
  timePeriodList: '.time-period-list'
};
