////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  05/11/2022    ZSunOo        77752           Initial Create
//  09/01/2022    BBARRON       89603           Re-render the lower table with same data when dropdown changes.
//                                              Needed to completely remove irrelevant rows from the DOM for correct styling
//  09/12/2022    BBARRON       89878           If chart area is not initially visible, don't render it.
//                                              But check to see if the chart becomes visible on window resize. 
//  12/09/2022    BBARRON       89878           Fix the way the chart checks whether it has already been loaded
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import d3 from 'd3';
import Pikaday from 'pikaday';
import moment from 'moment';
import { elements } from './elements';
import { Data, LineGraph, renderHistoricalPricesTable } from './classes';

const API_BASE = `/api/sitecore/HistoricalPricesDistributions`;

const data = new Data();
/**
 * Gets the start and end dates for a given range measured in the given number of months.
 * @param {number} numberOfMonths The number of months in the date range
 * @returns {object} An object with start and end date properties
 */
const getDatesByMonthAgo = function (numberOfMonths) {
  const now = new Date();
  return {
    start: moment.utc(now).subtract(numberOfMonths, 'months').format('MM/DD/YYYY'),
    end: moment.utc(now).format('MM/DD/YYYY')
  };
};

// onLoad function
export const loadScripts = (snippetData, maxData) => {

  const container = document.querySelectorAll(elements.historicalPricesContainer);
  if(container.length === 0) {
    // Page does not contain historical prices component.
    return;
  }

  /**
   * Gets the api endpoint url for the given start and end dates
   * @param {string} startDate The start date of the desired data 
   * @param {string} endDate The end date of the desired data
   * @returns 
   */
  const endPointUrl = function (startDate, endDate) {
    const ticker = document.querySelector(elements.historicalPricesContainer).getAttribute('data-ticker');
    if (startDate && endDate) {
      return `${API_BASE}/Get?ticker=${ticker}&from=${startDate}&to=${endDate}`;
    } else {
      return `${API_BASE}/Get?ticker=${ticker}`;
    }
  };

  /**
   * Gets the mock/dev data by type
   * @param {string} type (snippetData|maxData) The type of data to get 
   * @returns 
   */
  const getDevData = type => {
    switch (type) {
      case 'snippetData':
        return snippetData;
      case 'maxData':
        return maxData;
      default:
        return;
    }
  };

  const isDev = Boolean(snippetData) && Boolean(maxData);

  /**
   * Check whether the chart is loaded or not
   * @returns true if the chart container is not empty
   */
  const isChartLoaded = () => {
    const container = document.querySelector(elements.chartContainer);
    return container.innerHTML !== '';
  }

  /**
   * Check whether to render the graph/table every 2 seconds.
   * If the container is visible when checked, render the table and stop checking.
   */
  function checkRenderTable(isDev) {

    /**
     * If the container is visible, load the chart and return true. Otherwise return false.
     * @returns true if chart was loaded
     */
    var loadChartIfContainerVisible = (evt) => {
      let containerInner = document.querySelector(elements.historicalPricesContainerInner);
      if(isChartLoaded()) {
        if(evt && evt.type === "resize") {
          window.removeEventListener("resize", loadChartIfContainerVisible, false);
        }
        return false;
      }
      if (isElementVisible(containerInner)) {
        initialize(isDev);
        return true;
      }
      return false;
    };


    var chartWasLoadedOnFirstTry = loadChartIfContainerVisible();
    if(!chartWasLoadedOnFirstTry) {
      // If the chart was not loaded of first try, wait for user to resize the browser
      window.addEventListener("resize", loadChartIfContainerVisible, false);
    }
      
  }

  function isElementVisible(element) {
    if(!element) {
      return false;
    }
    let display = window.getComputedStyle(element, null).display;
    return display !== "none";
  }


  /**
   * Render and initialize the chart and table
   */
  function initialize(isDev) {
    // to / from dates
    const fromPicker = new Pikaday({
      field: document.querySelector(elements.historicalFromDate),
      format: 'M/D/YYYY'
    });

    const toPicker = new Pikaday({
      field: document.querySelector(elements.historicalToDate),
      format: 'M/D/YYYY'
    });

    let {selectorDates, selectorUrls} = getSelectorUrls(isDev);

    renderLineGraph(isDev, selectorUrls)
    .then((graph) => {
      activateControls(graph, selectorUrls, fromPicker, toPicker);
      activateHistoryTable(selectorDates, fromPicker, toPicker);

      // In the event the window is resized, re-render the chart to ensure it is the appropriate width at all screen sizes
      window.addEventListener('resize', (evt) => {
        window.requestAnimationFrame(() => {
          graph.reRender();
        });
      });
    })
    .catch(err => {
      console.error("Error rendering historical prices chart", err);
    });


  }


  function getSelectorUrls(isDev) {
    let selectorDates = '';
    let selectorUrls = [
      {
        selector: '.one-month',
        url: function () {
          selectorDates = getDatesByMonthAgo(1);
          const { start, end } = selectorDates;
          if (isDev) return getDevData('snippetData');
          return endPointUrl(start, end);
        }
      },
      {
        selector: '.three-month',
        url: function () {
          selectorDates = getDatesByMonthAgo(3);
          const { start, end } = selectorDates;
          if (isDev) return getDevData('snippetData');
          return endPointUrl(start, end);
        }
      },
      {
        selector: '.six-month',
        url: function () {
          selectorDates = getDatesByMonthAgo(6);
          const { start, end } = selectorDates;
          if (isDev) return getDevData('snippetData');
          return endPointUrl(start, end);
        }
      },
      {
        selector: '.twelve-month',
        url: function () {
          selectorDates = getDatesByMonthAgo(12);
          const { start, end } = selectorDates;
          if (isDev) return getDevData('snippetData');
          return endPointUrl(start, end);
        }
      },
      {
        selector: '.thirty-six-month',
        url: function () {
          selectorDates = getDatesByMonthAgo(36);
          const { start, end } = selectorDates;
          if (isDev) return getDevData('snippetData');
          return endPointUrl(start, end);
        }
      },
      {
        selector: '.sixty-month',
        url: function () {
          selectorDates = getDatesByMonthAgo(60);
          const { start, end } = selectorDates;
          if (isDev) return getDevData('snippetData');
          return endPointUrl(start, end);
        }
      },
      {
        selector: '.all',
        url: function () {
          selectorDates = '';
          if (isDev) return getDevData('maxData');
          return endPointUrl();
        }
      }
    ];

    return {selectorDates, selectorUrls};
  }

  

  
  /**
   * Renders the initial line graph
   * @param {boolean} isDev Whether to render in DEV mode or live mode
   * @param {object[]} selectorUrls A list of selectors and urls for each time series button
   */
  async function renderLineGraph(isDev, selectorUrls) {
    // default
    return new Promise ((resolve, reject) => {
      let graph = null;
      const container = document.querySelector(elements.chartContainer);
      if (container.innerHTML === '') {
        if (isDev) {
          const renderData = data.process(getDevData('snippetData'));
          graph = new LineGraph({
            container: document.querySelector(elements.chartContainer),
            data: renderData,
            distributionCheckbox: document.querySelector(elements.distributionCheckbox)
          });

          graph.render();
          resolve(graph);
          // hacky way to render the graph
          setTimeout(() => {
            document.querySelector('.one-month').click();
          }, 1000);
        } else {
          d3.json(selectorUrls[0].url(), function (json) {
            graph = new LineGraph({
              container: document.querySelector(elements.chartContainer),
              data: data.process(json),
              distributionCheckbox: document.querySelector(elements.distributionCheckbox)
            });
            graph.render();
            resolve(graph);
          });
        }
      } else {
        reject('not renedering graph becase container is not empty');
      }
    });
  }

  
  /**
   * Removes active state on all time period buttons (1M, 3M, 1Y, etc.)
   */
  const resetButtonContainer = function () {
    const buttonContainer = document.querySelector(elements.timePeriodList);
    buttonContainer.querySelectorAll('li').forEach(li => li.classList.remove('active'));
  };


  /**
   * Wires up the click event on the given date-range button
   * @param {HtmlElement} button The button to activate
   * @param {*} url The api url to call
   * @param {*} isDev Whether or not this is DEV mode
   * @param {*} graph The graph to update
   */
  function activateDateButton(button, url, isDev, graph) {
    button.addEventListener('click', e => {
      e.preventDefault();
      resetButtonContainer();
      button.parentNode.classList.add('active');

      if (isDev) {
        const updateData = data.process(url);
        graph.update(updateData);
      } else {
        d3.json(url, function (json) {
          graph.update(data.process(json));
        });
      }
    });
  }

  /**
   * Activates the line graph controls
   * @param {LineGraph} graph The graph to update when controls are used
   * @param {object} selectorUrls A list of {selector, url} objects
   * @param {DatePicker} fromPicker
   * @param {DatePicker} toPicker
   */
  function activateControls(graph, selectorUrls, fromPicker, toPicker) {
    selectorUrls.forEach(function (obj) {
      const element = document.querySelector(obj.selector);
      activateDateButton(element, obj.url(), isDev, graph);
    });

    const showButton = document.querySelector(elements.updateHistoryButton);
    showButton.addEventListener('click', () => {
      let [start, end] = [fromPicker.toString(), toPicker.toString()];
      if (!start || !end) {
        alert('Please select a date range');
        return;
      }

      if (isDev) {
        const updateData = start && end ? getDevData('snippetData') : getDevData('maxData');
        graph.update(data.process(updateData));
      } else {
        d3.json(endPointUrl(start, end), function (json) {
          graph.update(data.process(json));
        });
      }
    });
  }

  /**
   * Activate the history table and controls below the graph
   * @param {object} selectorDates 
   */
  function activateHistoryTable(selectorDates, fromPicker, toPicker) {
    const showHistoryTable = document.querySelector(elements.showHistoryTable);
    showHistoryTable.addEventListener('click', () => {
      document.querySelector(elements.historicalPricesContainerTable).classList.toggle('hide');
    });

    const downloadExcelButton = document.querySelector(elements.downloadExcelButton);
    if (downloadExcelButton) {
      downloadExcelButton.addEventListener('click', () => {
        let [start, end] = [fromPicker.toString(), toPicker.toString()];
        const ticker = document.querySelector(elements.historicalPricesContainer).getAttribute('data-ticker');
        const filter = document.querySelector(elements.historyTableSelect).value;

        if (start === '' || end === '') {
          start = selectorDates.start;
          end = selectorDates.end;
        }

        if (!isDev) {
          // for production
          let path;
          if (start && end) {
            path = `${API_BASE}/DownloadExcel?ticker=${ticker}&from=${start}&to=${end}&filter=${filter}`;
          } else {
            path = `${API_BASE}/DownloadExcel?ticker=${ticker}&filter=${filter}`;
          }
          window.open(path, '_blank');
        }
      });
    }

    const downloadCsvButton = document.querySelector(elements.downloadCsvButton);
    if (downloadCsvButton) {
      downloadCsvButton.addEventListener('click', () => {
        let [start, end] = [fromPicker.toString(), toPicker.toString()];
        const ticker = document.querySelector(elements.historicalPricesContainer).getAttribute('data-ticker');
        const filter = document.querySelector(elements.historyTableSelect).value;

        if (start === '' || end === '') {
          start = selectorDates.start;
          end = selectorDates.end;
        }

        if (isDev) {
          // for dev
          if (start && end) {
            alert(`get filtered excel items. ticker: ${ticker}, filter: ${filter}`);
          } else {
            alert('get max excel items');
          }
        } else {
          // for production
          let path;
          if (start && end) {
            path = `${API_BASE}/DownloadCsv?ticker=${ticker}&from=${start}&to=${end}&filter=${filter}`;
          } else {
            path = `${API_BASE}/DownloadCsv?ticker=${ticker}&filter=${filter}`;
          }
          window.open(path, '_blank');
        }
      });
    }

    const historyTableSelect = document.querySelector(elements.historyTableSelect);
    if (historyTableSelect) {
      historyTableSelect.addEventListener('change', e => {
        const { value } = e.target;
        // Re-render the table with same data. Completely remove irrelevant rows from the DOM for correct styling
        renderHistoricalPricesTable(value);
      });
    }
  }

  checkRenderTable(isDev);
};
