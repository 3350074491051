// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   05/24/2022    ZSunOo        82637           Initial Create -- Added horizontal Table Scripts
//   05/27/2022    ZSunOo        85181           Nested Children
//   08/04/2022    GDEME         89643           Fund Details Pages (all) - expand/collapse functionality
//   08/07/2022    BBARRON       89643         Overhaul expanding/collapsing footnotes. Default to expanded, disable collapsing if too short
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const horizontalTableScript = {
  loadScripts: () => {

    const defaultToCollapsed = false;
    const expandCollapseThreshold = 180;

    const handleExpandClick = evt => {
      evt.preventDefault();
      const parent = evt.currentTarget.parentNode;
      if (parent.classList.contains('factsheet-htable-footer')) {
        parent.classList.remove('factsheet-htable-footer--collapsed');
        parent.classList.add('factsheet-htable-footer--expanded');
      }
    };

    const handleCollapseClick = evt => {
      evt.preventDefault();
      const parent = evt.currentTarget.parentNode;
      if (parent.classList.contains('factsheet-htable-footer')) {
        parent.classList.remove('factsheet-htable-footer--expanded');
        parent.classList.add('factsheet-htable-footer--collapsed');
      }
    };

    const expandButtons = document.querySelectorAll('.factsheet-htable-footer-controls--collapsed');
    expandButtons.forEach(button => {
      button.addEventListener('click', handleExpandClick, false);
    });

    const collapseButtons = document.querySelectorAll('.factsheet-htable-footer-controls--expanded');
    collapseButtons.forEach(button => {
      button.addEventListener('click', handleCollapseClick, false);
    });

    // Only show expand/collapse controls if footer is high enough to justify this.
    // If present, remove these buttons if the footnotes are too short.
    function resetFooters() {
      const tableFooters = document.querySelectorAll('.factsheet-htable-footer');
      tableFooters.forEach(footer => {   
        var footnotes = footer.querySelector('.factsheet-htable-footnotes');
        if(footnotes.offsetHeight > expandCollapseThreshold) {
          if(defaultToCollapsed){
            footer.classList.add('factsheet-htable-footer--collapsed');
          }else{
            footer.classList.add('factsheet-htable-footer--expanded');
          }
        } else {
          footer.classList.remove('factsheet-htable-footer--collapsed');
          footer.classList.remove('factsheet-htable-footer--expanded');
        }
      });
    }

    resetFooters();

    // Footer height can
    window.addEventListener('resize', function() {
      this.window.requestAnimationFrame(resetFooters);
    });


  }
};
