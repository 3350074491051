// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   12/18/2020    HJordan                       Initial create
//   01/28/2020    HJordan        51238          Support Show More & Show All interactions
//   04/28/2023    LBALL          103249         Update Markup in storybook for Show Less feature
//   05/09/2023    LBALL          105697         Add scrolling on pagination close
//   05/10/2023    LBALL          105732         Modify scrolling on Pagination close
//   05/11/2023    LBALL          103247         Updating .js to Save Expand and Collapse State using sessionsStorage
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const showMoreButtonClass = 'gs-cardgroup-showMore-btn';
const showAllButtonClass = 'gs-cardgroup-showAll-btn';
const showLessButtonClass = 'gs-cardgroup-showLess-btn';
const showMoreHiddenClass = 'gs-cardgroup-showMore--hidden';
const showAllHiddenClass = 'gs-cardgroup-showAll--hidden';
const showLessHiddenClass = 'gs-cardgroup-showLess--hidden';
const cardHiddenClass = 'gs-card--hidden';

/**
 * Function which accepts an element,a toggle flag, and a class which
 * hides a card, ir order to hide/show the element based on the toggle
 * flag
 *
 * @param {Node} element element to be manipulated
 * @param {boolean} toggle flag to identify hidden or visible
 * @param {string} hiddenClass class which hides cards
 */
const toggleCardHidden = (element, toggle, hiddenClass) => {

  if (!element) {
    return;
  }

  if (toggle) {
    element.classList.add(hiddenClass);
  } else {
    element.classList.remove(hiddenClass);
  }
};

/**
 * Function which accepts a list of tags which are currently hidden, and
 * toggles them to be revealed
 *
 * @param {Array} hiddenCardList list of hidden Tag nodes
 */
const displayHiddenCards = hiddenCardList => {
  if (hiddenCardList && hiddenCardList.length > 0) {
    hiddenCardList.forEach(card => {
      toggleCardHidden(card, false, cardHiddenClass);
    });
  }
};

/**
 * Function which accepts a list of tags which are currently hidden
 * or calculates them by default, and toggles them to be revealed
 *
 * @param {Array} hiddenCardList list of hidden Tag nodes
 */

export const hideAllCardsInGroup = (cardGroup) => {
  const allCardList = cardGroup ?? document.querySelectorAll('.gs-card');
  if (allCardList && allCardList.length > 0) {
    allCardList.forEach(card => {
      toggleCardHidden(card, true, cardHiddenClass);
    });
  }
};

/**
 * Click handler which handles triggers when the "Show More" button in a card
 * group is clicked. The handler then takes all of the cards in the card group
 * and ensure the correct classes are applied so that all cards are revealed
 * based on the designated number of cards to be displayed
 *
 * The "Show More" button is then hidden from the view once amountToShow exceeds
 * number of remaining hidden items
 *
 * @param {EventTarget} event
 * @param {number} amountToShow number of elements to show
 * @param {string} classToShow number of elements to show
 */
export const showMoreCardsInGroup = (event, amountToShow = 6) => {

  let cardGroup = getCardGroupObject(event);

  if (cardGroup.group) {

    const cardsToShow = Array.from(cardGroup.groupAllHiddenFilteredCards ?? null).slice(0, amountToShow);
    const cardShowLessButtonWrapper = cardGroup.group.querySelector('.gs-cardgroup-showLess');
    const cardShowMoreButtonWrapper = cardGroup.group.querySelector('.gs-cardgroup-showMore');

    displayHiddenCards(cardsToShow);

    if (cardGroup.groupAllHiddenFilteredCards.length <= amountToShow) {
      toggleCardHidden(cardShowMoreButtonWrapper, true, showMoreHiddenClass)
      toggleCardHidden(cardShowLessButtonWrapper, false, showLessHiddenClass);
    } else {
      toggleCardHidden(cardShowMoreButtonWrapper, false, showMoreHiddenClass);
      toggleCardHidden(cardShowLessButtonWrapper, true, showLessHiddenClass);
    }
  }
};

/**
 * Scrolling function that returns the user to the "Header" section of a card group; 
 * the scrolling is calculated relative to the mobile or desktop navigation menu.
 * 
 * @param {Object} cardGroup
 */

export const scrollToCardCategory = (cardGroup) => {

  if (cardGroup.group.parentNode) {

    const groupCategoryScrollOffsetElement = window.innerWidth < 1024 ?
      document.querySelector('.gs-header-mobile-nav-container') :
      document.querySelector('.gs-header-desktop-nav-container');

    const groupCategoryWrapperTag = cardGroup.group.parentNode.getAttribute("data-tag");

    if (groupCategoryWrapperTag && groupCategoryWrapperTag !== "undefined") {

      let scrollOffsetGroupHeight = cardGroup.group.parentNode.getBoundingClientRect().top + window.pageYOffset ?? 0;

      if (groupCategoryScrollOffsetElement && groupCategoryScrollOffsetElement.clientHeight) {
        scrollOffsetGroupHeight = scrollOffsetGroupHeight - groupCategoryScrollOffsetElement.clientHeight;
      }

      window.scrollTo(
        {
          behavior: "smooth",
          top: scrollOffsetGroupHeight
        }
      );

    } else {
      cardGroup.group.parentNode.scrollIntoView(
        {
          behavior: "smooth",
          block: "end"
        }
      );
    }
  }
  return;
}


/**
 * Anonymous function that reads the sessionStorage of a cardgroup from the button element.
 * This function should dynamically open a cardgroup's pagination.
 * 
 * This is only invoked during CardGroupScripts().
 * 
 * @param {button} buttonElement
 */


export const initSessionPagination = (button) => {

  const groupCategoryWrapperTag = button.parentNode.parentNode.parentNode.getAttribute("data-tag");
  if(groupCategoryWrapperTag) {

    let paginationSessionState = sessionStorage.getItem(groupCategoryWrapperTag);

   if(paginationSessionState && paginationSessionState.toLowerCase() == "open") {
    button.dispatchEvent(new MouseEvent("click"));
   }
  }
}

/**
 * Click handler which handles triggers when the "Show All" button in a card
 * group is clicked. The handler then takes all of the cards in the card group
 * and ensure the correct classes are applied so that all cards are revealed
 *
 * The "Show All" button is then hidden from the view
 * @param {EventTarget} event
 */
export const showAllCardsInGroup = (event) => {

  event.preventDefault();
  event.stopPropagation();

  const cardGroup = getCardGroupObject(event);
  const cardButtonWrapper = cardGroup.groupButtonWrapper ?? cardGroup.groupShowAllButtonWrapper;
  const groupCategoryWrapperTag = cardGroup.group.parentNode.getAttribute("data-tag");

  if (groupCategoryWrapperTag) {
    sessionStorage.setItem(groupCategoryWrapperTag, "open");
  }

  if (cardGroup.group) {
    displayHiddenCards(cardGroup.groupAllHiddenFilteredCards);
    toggleCardHidden(cardButtonWrapper, true, showAllHiddenClass);
    toggleCardHidden(cardGroup.group.querySelector('.gs-cardgroup-showLess'), false, showLessHiddenClass);
  }
};

/**
 * Anonymous function to return CardGroup UI elements
 *
 * @param {EventTarget} event
 */
export const getCardGroupObject = (event) => {

  let cardGroup = {
    groupButtonWrapper: null,
    groupShowAllButtonWrapper: null,
    groupShowMoreButtonWrapper: null,
    groupShowLessButtonWrapper: null,
    groupAllVisibleCards: null,
    groupAllHiddenCards: null,
    groupAllHiddenFilteredCards: [],
    group: null
  }


  if (event) {
    event.preventDefault();
    event.stopPropagation();
    cardGroup.groupButtonWrapper = event.target.parentNode;
    cardGroup.group = cardGroup.groupButtonWrapper.parentNode;
  } else {
    cardGroup.groupShowAllButtonWrapper = document.querySelector('.gs-cardgroup-showAll');
    cardGroup.groupShowMoreButtonWrapper = document.querySelector('.gs-cardgroup-showMore');
    cardGroup.groupShowLessButtonWrapper = document.querySelector('.gs-cardgroup-showLess');
    cardGroup.group = document.querySelector('.gs-cardgroup-content');
  }

  if (cardGroup.group) {
    cardGroup.groupAllVisibleCards = cardGroup.group.querySelectorAll('.gs-cardgroup-content > a.gs-card, a.gs-card:not(.gs-card--hidden, .gs-card--filtered-out)');
    cardGroup.groupAllHiddenCards = cardGroup.group.querySelectorAll('.gs-card--hidden');
    cardGroup.groupAllHiddenCards.forEach(card => {
      if (!card.classList.contains('gs-card--filtered-out')) {
        cardGroup.groupAllHiddenFilteredCards.push(card);
      }
    });
  }

  return cardGroup;
};

/**
 * Click handler which handles triggers when the "Show Less" button in a card
 * group is clicked. The handler then takes all of the cards in the card group
 * and ensure the correct classes are applied so that all cards are revealed
 * based on the designated number of cards to be displayed
 *
 * The "Show Less" button is then hidden from the view when the minimum number 
 * of elements are displaying.
 *
 * @param {EventTarget} event
 * @param {number} amountToShow number of elements to show
 */
export const showLessCardsInGroup = (event, amountToShow = 3) => {

  const cardGroup = getCardGroupObject(event);

  if (cardGroup.group) {

    const cardsToHide = Array.from(cardGroup.groupAllVisibleCards).slice(amountToShow, cardGroup.groupAllVisibleCards.length);
    const cardButtonWrapper = cardGroup.groupButtonWrapper ?? cardGroup.groupShowMoreButtonWrapper;
    const cardDisplayButton = cardGroup.group.querySelector('.gs-cardgroup-showMore') ??
      cardGroup.group.querySelector('.gs-cardgroup-showAll');
    const groupCategoryWrapperTag = cardGroup.group.parentNode.getAttribute("data-tag");

    if (groupCategoryWrapperTag) {
      sessionStorage.removeItem(groupCategoryWrapperTag);
    }

    let cardDisplayClass = null;

    if (cardDisplayButton && cardDisplayButton.classList.contains('gs-cardgroup-showMore')) {
      cardDisplayClass = showMoreHiddenClass;
    } else if (cardDisplayButton && cardDisplayButton.classList.contains('gs-cardgroup-showAll')) {
      cardDisplayClass = showAllHiddenClass;
    }

    hideAllCardsInGroup(cardsToHide);
    toggleCardHidden(cardButtonWrapper, true, showLessHiddenClass);
    toggleCardHidden(cardDisplayButton, false, cardDisplayClass);
    scrollToCardCategory(cardGroup);

  }

};

/**
 * Scripts which must be executed on page load in order to establish the interaction capabilities
 * of Card Groups. Running the script applies the required listeners to handle the "Show More" and
 * "Show All" capabilities
 * functionality
 */
export const cardGroupScripts = () => {
  const showMoreButtons = document.getElementsByClassName(showMoreButtonClass);
  const showAllButtons = document.getElementsByClassName(showAllButtonClass);
  const showLessButtons = document.getElementsByClassName(showLessButtonClass);

  if (showAllButtons && showAllButtons.length > 0) {
    for (let i = 0; i < showAllButtons.length; i++) {
      showAllButtons[i].addEventListener('click', showAllCardsInGroup);
      initSessionPagination(showAllButtons[i]);
    }
  }

  if (showMoreButtons && showMoreButtons.length > 0) {
    for (let i = 0; i < showMoreButtons.length; i++) {
      showMoreButtons[i].addEventListener('click', event => showMoreCardsInGroup(event, 6));
      initSessionPagination(showMoreButtons[i]);  
    }
  }

  if (showLessButtons && showLessButtons.length > 0) {
    for (let i = 0; i < showLessButtons.length; i++) {
      showLessButtons[i].addEventListener('click', event => showLessCardsInGroup(event, 3));
    }
  }
};
