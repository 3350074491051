////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  10/25/2023    BBARRON       117497          Initial create
//  11/15/2023    BBARRON       118393          Allow listeners to add their own validation function that must bass
//                                              before they are notified while still notifying other listeners
//  12/05/2023    BBARRON       119988          Revert the above change, did not resolve the issue, caused a drop in events recoreded in GA
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * The polling interval. How often to check to see 
 * if the cookie has been loaded (in milliseconds)
 */
const interval = 500;

/**
 * A hashtable of cookie notifiers. One for each cookie.
 * The notifyWhenCookieSet will use this to manage resources
 * and ensure only one notifier is created for each cookie name.
 */
const cookieNotifiers = {};

/**
 * Gets all cookies and returns them as an object
 * where key = cookieName and value = cookieValues
 * @returns The cookies ad an object
 */
export const getCookies = () => {
    return document.cookie
        .split(';') // Separate by cookie
        .map(k => k.trim()) // Trim whitespace
        .reduce((result, k, i) => { // Save keys/values to an object
            const [key, value] = k.split('=');
            result[key] = decodeURI(value);
            return result;
        }, {});
};

/**
 * Gets a single cookie value by name or null if not set
 * @param {string} cookieName  The name of the cookie to watch for
 * @returns The cookie value or null
 */
export const getCookie = (cookieName) => {
    let cookies = getCookies();
    if (cookies.hasOwnProperty(cookieName)) {
        return cookies[cookieName];
    }
    return null;
};

/**
 * Determines whether a cookie with the given name is set
 * @param {string} cookieName  The name of the cookie to watch for
 * @returns true if the cookie is set
 */
export const cookieExists = (cookieName) => {
    let cookie = getCookie(cookieName);
    return cookie !== null && typeof cookie !== 'undefined';
};

/**
 * This class follows a pub/sub pattern, polling a given cookie and
 * notigying all listeners when the cookie is set by calling the given callback method.
 * If the cookie is alredy set, then just call the callback function immediately
 */
class CookieNotifier {
    constructor(cookieName) {
        this.name = cookieName
        this.loaded = false;
        this.callbacks = [];
        this.checkCookieExists();
    }

    /**
     * Add a callback function to this cookie notifier that will be called once the cookie is set in the browser
     * @param {function} callback A callback function that takes a cookie name as a parameter
     */
    add(callback) {
        if(this.loaded) {
            let cookieValue = getCookie(this.name);
            callback(cookieValue);
        } else {
            this.callbacks.push(callback);
        }
    }

    checkCookieExists() {
        if (!cookieExists(this.name)) {
            window.setTimeout(this.checkCookieExists.bind(this), interval) // try again in a little while
        } else {
            console.log(`${this.name} cookie has been set`);
            // notify all listeners that the cookie has been set.
            // pass in the cookie value to the callback
            let cookie = getCookie(this.name);
            this.loaded = true;
            this.callbacks.forEach(c => {
                c(cookie);
            });
            this.callbacks = [];
        }
    }
}


/**
 * Given a cookie name and a callback function, this method calls the callback function when the desired cookie has been set in the browser.
 * @param {string} cookieName The name of the cookie to watch for
 * @param {the callback function} callback This function gets called once the cookie value is knowne. cookie value is passed into the callback
 */
export const notifyWhenCookieSet = (cookieName, callback) => {
    console.log(`Listening for ${cookieName} cookie`);
    if (!cookieNotifiers.hasOwnProperty(cookieName)) {
        cookieNotifiers[cookieName] = new CookieNotifier(cookieName);
    }
    cookieNotifiers[cookieName].add(callback);
};