// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   12/09/2020    Jjacob        52558           Inital Create
//   04/20/2021    BBARRON       59918           Prevented login from automatically closing when on home page
//   12/21/2022    BBARRON       96859           Added show/hide password button
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const headerLoginScripts = {
  loadScripts: () => {
    const handleLoginClick = () => {
      const login = document.getElementById('gs_header_login');
      login.classList.toggle('--active');
    };

    function handleLoginScroll() {
      const isHome = isHomePage();
      const isDesktop = window.matchMedia('(min-width: 1024px)').matches;

      // Sticky login for mobile and home page, otherwise hide login on scroll
      if(isHome || !isDesktop) {
        return;
      }
      
      const login = document.getElementById('gs_header_login');
      login.classList.remove('--active');
    }

    function isHomePage() {
      return window.location.pathname === "/" || window.location.pathname === "/en" || window.location.pathname === "/en/";
    }

    /**
     * Enable/disable the login button depending on whether the username/password fields are empty
     */
    function handleLoginButton(){
      const usernameVal = document.getElementById('userName').value;
      const passwordVal = document.getElementById('password').value;
      const loginButtons = document.querySelectorAll('.gs-login-section-button.--validate > .gs-link');
      if (usernameVal !== '' && passwordVal !== '') {
        for (let i = 0; i < loginButtons.length; i++) {
          loginButtons[i].classList.remove('gs-link--disabled');
        }
      } else {
        for (let i = 0; i < loginButtons.length; i++) {
          loginButtons[i].classList.add('gs-link--disabled');
        }
      }
    }

    /**
     * Toggles password visibility
     */
    function togglePasswordVisible(evt) {
      let passwordContainer = document.querySelector('.gs-login-password-container');
      if(passwordContainer) {
        passwordContainer.classList.toggle('password-hidden');
        let passwordInput = document.querySelector('.gs-login-password-container input');
        let inputType = passwordContainer.classList.contains('password-hidden') ? "password" : "text";
        passwordInput.type = inputType;
      }
    }

    // Add clieck event handlers to password visibility buttons
    document.querySelectorAll('.gs-login-password-container > span').forEach(btn => {
      btn.addEventListener('click', togglePasswordVisible, false);
    });

    if (document.getElementById('gs_header_login')) {
      document.getElementById('gs_header_mobile_login_link').addEventListener('click', () => {
        handleLoginClick();
      });
      document.getElementById('gs_header_login_link').addEventListener('click', () => {
        handleLoginClick();
      });
      if (document.getElementById('userName')) {
        document.getElementById('userName').addEventListener('keyup', () => {
          handleLoginButton();
        });
      }
      if (document.getElementById('password')) {
        document.getElementById('password').addEventListener('keyup', () => {
          handleLoginButton();
        });
      }

      window.addEventListener('scroll', handleLoginScroll);
    }
  }
};
