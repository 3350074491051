////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  08/03/2023    BBARRON       110687          Initial create
//  08/08/2023    BBARRON       111013          Default all anchor links to smooth scrolling without having to add a css class
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { scrollToAnchorInUrl } from '../utils/scroll-utils';

/**
 * We change the default behavior for anchor link clicks. The user will now smoothly scroll to the anchor target, leaving room for the sticky header.
 * 
 * There are two css classes that can be added to the anchor link to get around this new custom behavior:
 *   * gs-anchor--default will scroll the user instantly to the anchor using the browser-s default behavior, not accounting for sticky headers
 *          This might be needed if the sticky header is ever hidden on a specific page or for a specific business reason
 *   * gs-anchor--instant will scroll the user instantly to the anchor, but it will account for sticky header height
 *          This will be used most often when the new custom behavior is overridden
 */
export const smoothScrollAnchorLinkScripts = {
    loadScripts: () => {


        // We listen for the click at the document level so we can get anchor links that were not yet on the page when this script loads
        document.addEventListener("click", function(evt) {

            // We make sure the click was on an anchor link
            if (evt.target.tagName !== "A" || !evt.target.getAttribute("href") || !evt.target.getAttribute("href").startsWith("#")) {
                return;
            }

            console.log(evt.target, evt.target.classList);

            // We allow content authors to break out of this custom scrolling behavior by adding a specific css class to anchor links
            if(evt.target.classList.contains('gs-anchor--default')) {
                return;
            }

            evt.preventDefault();
            const url = evt.target.href;

            // We allow content authors to specify that the user should scroll instantly to an anchor, but still account for sticky header with this css class
            if(evt.target.classList.contains('gs-anchor--instant')) {
                scrollToAnchorInUrl(url, {behavior: 'instant'});
                return;
            }

            // If none of those special css classes are in place the default behavior for anchor links is to smooth scroll. No special css class needed
            scrollToAnchorInUrl(url, {behavior: 'smooth'});
        }, false);
    }
};