/* eslint-disable spaced-comment */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  12/09/2020    Jjacob        52558           Inital Create
//  04/20/2021    BBARRON       59918           Prevented login from automatically closing when on home page
//  12/21/2022    BBARRON       96859           Added show/hide password button
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * We want the login menu to appear on hover for desktop and on click for mobile.
 *
 */

export const loginDropdownScripts = {
  loadScripts: () => {
    const loginMenu = document.getElementById('gs_login_menu');
    const loginBtnDesktop = document.getElementById('gs_header_login_link');
    const loginBtnMobile = document.getElementById('gs_header_mobile_login_link');
    const mobileNavBreakpoint = 992;
    /**
     * Moves the login menu horizontally to align with the sign in button
     * (desktop only)
     */
    const setDropdownPosition = () => {
      if (window.innerWidth < mobileNavBreakpoint) {
        loginMenu.style.left = '0px';
        return;
      }

      const bounds = loginBtnDesktop.getBoundingClientRect();
      const position = bounds.left;
      loginMenu.style.left = position + 'px';
    };

    /**
     * Wait a fraction of a second, and then check to see if the login menu can be hidden
     * (desktop only)
     */
    const handleMouseLeaveDesktop = () => {
      window.setTimeout(() => {
        if (
          loginMenu.dataset.mode === 'desktop' &&
          !loginMenu.matches(':hover') &&
          !loginBtnDesktop.matches(':hover')
        ) {
          loginMenu.classList.remove('--active');
        }
      }, 250);
    };

    /**
     * Hide the login menu if the click is outside of it (for both desktop and mobile)
     * @param {MouseEvent} evt The click event
     */
    const handleClickOutside = evt => {
      if (
        loginMenu &&
        !loginMenu.contains(evt.target) && // Clicked outside the login menu
        !loginBtnDesktop.contains(evt.target) && // Clicked outside the desktop login button
        !loginBtnMobile.contains(evt.target) // Clicked outside the mobile login button
      ) {
        loginMenu.classList.remove('--active'); // Hide the login menu
      }
    };

    // If we don't have a vertical login submenu to control, then exit early
    if (!loginMenu) {
      return;
    }

    // handle desktop login menu show/hide
    if (loginBtnDesktop) {
      loginBtnDesktop.addEventListener('mouseenter', evt => {
        loginMenu.classList.add('--active');
        loginMenu.dataset.mode = 'desktop';
        setDropdownPosition();
      });

      loginMenu.addEventListener('mouseenter', evt => {
        loginMenu.classList.add('--active');
      });

      loginBtnDesktop.addEventListener('mouseleave', handleMouseLeaveDesktop);

      loginMenu.addEventListener('mouseleave', handleMouseLeaveDesktop);
    }

    // handle mobile login menu show/hide
    if (loginBtnMobile) {
      loginBtnMobile.addEventListener('click', evt => {
        evt.preventDefault();
        loginMenu.style.left = '0px';
        loginMenu.dataset.mode = 'mobile';
        loginMenu.classList.toggle('--active');
      });
    }

    // Add an event listener for clicks on the document to hide the menu if clicked outside
    document.addEventListener('click', handleClickOutside);

    // Add an event listener for window resize to adjust dropdown position using requestAnimationFrame
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => {
        loginMenu.classList.remove('--active');
      });
    });

    // Analytics on login link clicks
    const loginLinks = Array.from(loginMenu.querySelectorAll('a'));
    loginLinks.forEach(link => {
      link.addEventListener('click', evt => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: link.dataset.event || 'header_login',
          page_url: window.location.href
        });
      });
    });
  }
};
