// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   05/11/2022    ZSunOo        77752           Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { loadScripts } from './loadScripts';
import { destroyScripts } from './destroyScripts';

export const priceDistributionScripts = {
  loadScripts,
  destroyScripts
};
