////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  10/11/2022    BBARRON       93634         Initial create
//  01/11/2023    BBARRON       89895         Fix scroll position bug when clicking browser back button
//  08/02/2023    BBARRON       110687        Removed unused references and variables, updated scroll-util reference
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import {
    renderDailyPerformanceTable,
    renderMonthlyPerformanceTable,
    renderQuarterlyPerformanceTable,
    renderMorningstarPerformanceTable,
    renderFootnotes
  } from './rendering.js';

import { getFragmentFromUrl } from '../utils/scroll-utils.js';

export const fundsListingScripts = {
    loadScripts: (mockData, shareClass, simulatedAnchorId) => {
        const containerSelector = '.fund-performance .table-container';
        const footnotesContainerSelector = '.footnote-container';
        const buttonsSelector = '.asof-date-selector .gs-button-toggle-group';
        const fundsListingContainer = document.querySelector(containerSelector);
        const fundsListingFootnotesContainer = document.querySelector(footnotesContainerSelector);
        const buttonsContainer = document.querySelector(buttonsSelector);
        let asOfDates = { daily: null, monthly: null, quarterly: null, morningstar: null };

        // Check for container
        if (!fundsListingContainer) {
            return;
        }

        getButtonToggleGroupComponent()
        .then(initialize)
        .catch((err) => {
            console.error("Error rendering fund performance tables", err);
        });

        async function initialize(toggleComponent) {
            // Wire up the as of date buttons
            var currentTab = toggleComponent.getSelectedValue();
            toggleComponent.container.addEventListener('gs.tabchange', (evt) => { 
                switchTable(evt.detail.selectedValue, simulatedAnchorId); 
            }, false);

            // Make sure share class links reflect the desired anchor
            window.addEventListener('hashchange', () => {
                addAnchorToShareClassLinks();
                jumpToFundCategoryAnchor(currentTab, simulatedAnchorId);
            }, false);

            // Reset browser scroll position to top of page until data is loaded.
            // Helpful in the event that the user has previously scrolled down the page, navigated away, and then presses the browser back button.
            if(window.history && 'scrollRestoration' in window.history) {
                window.history.scrollRestoration = 'manual';
            }
            window.scrollTo({top: 0, left: 0});

            // Load the data
            if (!shareClass) {
                shareClass = getShareClassFromQueryString();
            }
            let shareClassCode = shareClassToShareClassCD(shareClass);
            var data = await loadData(shareClassCode);
            console.log(data);
            addAnchorToShareClassLinks();
            renderTables(currentTab, data);
            
        }

        function destroyFundTables() {
            let tables = fundsListingContainer.getElementsByTagName('table');
            for(let index = tables.length - 1; index >= 0; index -= 1) {
                tables[index].parentNode.removeChild(tables[index]);
            }
        }

        function renderTables(currentTab, data) {
            let daily = renderDailyPerformanceTable(data);
            let monthly = renderMonthlyPerformanceTable(data);
            let quarterly = renderQuarterlyPerformanceTable(data);
            let morningstar = renderMorningstarPerformanceTable(data);

            window.dateDaily = data.DateDaily;
            window.dateMonthly = data.DateMonthly;
            window.dateQuarterly = data.DateQuarterly;
            window.dateMorningStar = data.DateMorningStar;

            asOfDates = {
                daily: data.DateDaily,
                monthly: data.DateMonthly,
                quarterly: data.DateQuarterly,
                morningstar: data.DateMorningStar
            };

            // Hide the ajax spinner
            const loaderContainer = document.querySelector('.spinner-container');
            if (loaderContainer) {
                loaderContainer.style.display = 'none';
            }

            destroyFundTables();

            fundsListingContainer.appendChild(daily);
            fundsListingContainer.appendChild(monthly);
            fundsListingContainer.appendChild(quarterly);
            fundsListingContainer.appendChild(morningstar);

            renderFootnotes(data, fundsListingFootnotesContainer);
            switchTable(currentTab, simulatedAnchorId);
            jumpToFundCategoryAnchor(currentTab, simulatedAnchorId);
        }
        
        function switchTable(selectedTab, simulatedAnchorId) {
            if(!selectedTab) {
                return;
            }

            // Show/hide tables
            let tables = fundsListingContainer.getElementsByTagName('table');
            tables = Array.from(tables);
            tables.forEach(t => {
                if(t.dataset.type === selectedTab) {
                    t.style.display = 'table';
                } else {
                    t.style.display = 'none';
                }
            });

            // Update as of dates
            let desktop = document.getElementById('desktopAsOfDate');
            let mobile = document.getElementById('mobileAsOfDate');
            let asOfDate = asOfDates[selectedTab];

            desktop.innerHTML = asOfDate;
            mobile.innerHTML = asOfDate;
        }


        async function getButtonToggleGroupComponent() {
            return new Promise((resolve, reject) => {
                if(!buttonsContainer) {
                    reject("No buttons container on page");
                }
                if(buttonsContainer.gsComponent) {
                    resolve(buttonsContainer.gsComponent);
                }
                buttonsContainer.addEventListener('gs.load', (evt) => {
                    if(evt.detail.component) {
                        resolve(evt.detail.component);
                    } else {
                        reject("No component for this buttons container")
                    }
                }, false);
            });
        }


        function getShareClassFromQueryString() {
            const params = new URLSearchParams(window.location.search);
            const defaultShareClass = 'investor';
        
            if (!params.has('sclass')) {
                return defaultShareClass;
            }
        
            return params.get('sclass');
        }
        
        /**
         * Converts the share class name "Investor/Institutional to a share class cd "R" or "I"
         * @param {string} shareClass
         * @returns the share class code
         */
        function shareClassToShareClassCD(shareClass) {
            if (!shareClass || shareClass.toLocaleLowerCase() !== 'institutional') {
            return 'R';
            }
    
            return 'I';
        }
    
        /**
         * Fetches data directly from API without looking in local storage
         * @param {*} shareClass
         */
        async function loadData(shareClass) {
            if(mockData) {
                return mockData;
            }

            try {
                const endpoint = `/api/sitecore/FundsListingData/Get?sclass=${shareClass}`;
                let response = await fetch(endpoint);
                let json = await response.json();
                return json;
            } catch (err) {
                console.error(err);
            }
        }

        function addAnchorToShareClassLinks() {
            let rawAnchor = getFragmentFromUrl();
            if(!rawAnchor) { return; }
            if(rawAnchor.indexOf('-daily')) {
                rawAnchor.replace('-daily', '');
            }
            let links = document.querySelectorAll('.share-class-selector .gs-shareclass-tab');
            links = Array.from(links);
            links.forEach(link => {
                let oldUrl = link.href;
                let newUrl = `${oldUrl}#${rawAnchor}`;
                if(oldUrl === newUrl) {
                    return;
                }

                // Make sure the old url doesn't already have an anchor, and replace it if so
                let anchorPosition = oldUrl.indexOf('#');
                if(anchorPosition >= 0) {
                    let initialUrl = oldUrl.substring(0, anchorPosition);
                    newUrl = `${initialUrl}#${rawAnchor}`;
                }
                link.href = newUrl;
            });
        }

        /**
         * For mobile, jumps the page to put the sticky container into focus,
         * but doesn't scroll the sticky container to a specific category
         */
        function jumpPageToStickyContainer() {
            const headerHeight = 97;
            const containerTopPadding = 32;
            const scrollPosition = window.scrollY;
            const container = document.getElementById('sticky-container');
            const relativePosition = container.getBoundingClientRect().top;
            const absolutePosition = scrollPosition + relativePosition;
            const offset = headerHeight - containerTopPadding;
            window.scrollTo({left: 0, top: absolutePosition - offset});
        }

        function jumpStickyContainerToCategory(categoryId) {
            
            if(!categoryId) {
                return;
            }
            var category = document.getElementById(categoryId);
            if(!category) {
                return;
            }
            if(window.innerWidth >= 1024) {
                jumpToCategoryDesktop(categoryId);
            } else {
                jumpToCategoryMobile(categoryId);
            }
        }

        function getScrollOffset(type) {
            const offsets = {
                desktop: {
                    siteHeader: 77,
                    stickyHeader: 122,
                    tableHeader: 64
                },
                mobile: {
                    siteHeader: 65,
                    stickyHeader: 143,
                    tableHeader: 64
                }
            }
            let actual = offsets[type];
            actual.total = actual.siteHeader + actual.stickyHeader + actual.tableHeader;
            return actual;
        }

        function jumpToCategoryDesktop(categoryId) {
            window.scrollTo({left: 0, top: 0});
            var category = document.getElementById(categoryId);
            let categoryTop = category.getBoundingClientRect().top;
            const totalOffsets = getScrollOffset('desktop').total;
            let newWindowY = window.scrollY + categoryTop + 1;
            window.scrollTo({left: 0, top: newWindowY - totalOffsets});
        }

        /**
         * If category top equals total offsets, then we scroll to the current scroll position (don't move).
         * If categroy top is greater than total offsets, we scroll further down within the container.
         * If category top is less than total offsets, we scroll further up within the container.
         */
        function jumpToCategoryMobile(categoryId) {
            jumpPageToStickyContainer();
            
            const container = document.getElementById('sticky-container');
            container.scrollTo({left: 0, top: 0 });
            var category = document.getElementById(categoryId);
            let categoryTop = category.getBoundingClientRect().top;
            let currentScroll = container.scrollTop;

            const totalOffsets = getScrollOffset('desktop').total - 13;
            let newScrollY = Math.max(currentScroll + categoryTop - totalOffsets);
            container.scrollTo({left: 0, top: newScrollY });
        }

        function rawAnchorToActualAnchor(currentTab, rawAnchor) {
            if(rawAnchor.indexOf("-daily") >= 0) {
                return rawAnchor.replace("-daily", `-${currentTab}`);
            }
            return `${rawAnchor.toLowerCase()}-${currentTab}`;
        }

        function validateAnchorId(anchorId) {
            let element = document.getElementById(anchorId);
            if(!element) {
                return false;
            }
            return true;
        }



        function jumpToFundCategoryAnchor(currentTab, simulatedAnchorId) {
            let rawAnchor = !simulatedAnchorId ? getFragmentFromUrl(simulatedAnchorId) : simulatedAnchorId;
            if(!rawAnchor) {
                return;
            }
            let actualAnchor = rawAnchorToActualAnchor(currentTab, rawAnchor);
            if(validateAnchorId(actualAnchor)) {
                jumpStickyContainerToCategory(actualAnchor);
            }
        }
    }
};