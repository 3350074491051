////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  04/12/2023    BBARRON       103463          Initial create
//  10/25/2023    BBARRON       117497          Add function to Push _ga cookie to data layer amd trigger GTM event
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const api = '/attribution/hash-phi';
const emailPattern = /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;

/**
 * Given an email address, this async function generates the hash
 * @param {string} email The email address to hash/anonmymize
 * @returns The hashed email address
 */
export const hashEmail = async (email) => {
    const url = '/attribution/hash-phi';
    const payload = { value: email };
    const response = await fetch(api, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
    const data = await response.json();
    
    if (data.Status === 'Success') {
        return data.HashedValue;
    } else {
        console.error("Attribution API response indicates an error has occurred", data);
        throw new Error('Request failed: ' + data.Status);
    }
}

/**
 * Any time the email address changes on any <input type="email"> field, 
 * hash the value and store it for attribution when the form is later submitted.
 * @param {InputEvent} evt The change event
 */
export const handleAttributionEmailChange = async (evt) => {
	const input = evt.target;
	const email = input.value.trim().toLowerCase();
	if (!emailPattern.test(email)) {
		return;
	}
	
	try {
		let hash = await hashEmail(email);
		input.dataset.emailHash = hash;
	} catch (error) {
		console.error(error);
	}
}

/**
 * Event listener for attribution. Listens for any form submit on the page. If the an email address is submitted with the form, 
 * stop the submit and push it through the attribution flow. Then disable this event handler and resubmit the form as usual.
 * If the form does not have an email address
 * @param {FormEvent} event The form submit event whose target is the form 
 */
export const handleAttributionFormSubmit = (event) => {
    var emailInput = event.target.querySelector('input[type="email"]');

    if(emailInput && window.dataLayer && emailInput.dataset.emailHash) {
        window.dataLayer.push({
            'event': 'attributionEmailFormSubmit',
            'attributionFormId': event.target.id,
            'attributionEmailHash': emailInput.dataset.emailHash
        });
        console.log('Attribution information pushed to data layer');
    }
}


export const handleGoogleAnalyticsCookieLoaded = (cookieValue) => {
    if (window.dataLayer && cookieValue) {
        window.dataLayer.push({
            'event': 'googleAnalyticsCookieLoaded',
            'googleAnalyticsCookieValue': cookieValue,
        });
        console.log('Attribution information pushed to data layer');
    }
}