// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   12/14/2020    JJacob        51694           Initial Create
//   12/16/2020    JJacob        50360           Created Testimonial Carousel
//   04/27/2021    BBARRON       59084           Add swipe detection to testimonials carousel
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const carouselScripts = {
  loadScripts: () => {
    const leftArrow = 'gs_testimony_left_arrow';
    const rightArrow = 'gs_testimony_right_arrow';

    var xDown = null;                                                        
    var yDown = null;
    var swipeResult = 0;

    const getTouches = (evt) => {
      return evt.touches;
    }                                                     

    // Record the starting point during a touch event
    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];                                      
        xDown = firstTouch.clientX;                                      
        yDown = firstTouch.clientY;                                      
    };                                                

    // Calculate the drag distance during the swipe action
    const handleTouchMove = (evt) => {
        if ( ! xDown || ! yDown ) {
            return;
        }

        const xUp = evt.touches[0].clientX;                                    
        const yUp = evt.touches[0].clientY;

        const xDiff = xUp - xDown;
        const yDiff = yUp - yDown;
        const xDist = Math.abs(xDiff);
        const yDist = Math.abs(yDiff);

        // minimum drag distance to count as a swipe
        const minSwipe = 100;

        // maximum drag distance before the carousel item locks into place
        const maxDrag = 200;

        // The direction of the drag
        const dragDirection = xDiff / xDist; // 1 or -1

        // The stopping point for the drag action
        const dragBoundary = maxDrag * dragDirection;

        //Allow the user to drag the active courael item up to maxDrag pixels in either direction as they swupe
        var positionAdjust = dragDirection < 0 ? Math.max(xDiff, dragBoundary) : Math.min(xDiff, dragBoundary);
        const currentItem = document.querySelector('.gs-testimony-content.--selected');
        currentItem.style.left = `${positionAdjust}px`;

        if ( xDist > yDist && xDist > minSwipe) {/*most significant*/
            if ( xDiff < 0 ) {
              //left swipe - next slide
              swipeResult = 1;
            } else {
              //right swipe - previous slide
              swipeResult = -1;
            }                       
        }                                            
    };

    /**
     * Handle the swipe event if one was intended
     */
    const handleTouchEnd = () => {
      if (swipeResult !== 0) {
        var nextIndex = getIndexRelativeToCurrentSlide(swipeResult);
        
        setActiveSlide(nextIndex);
        swipeResult = 0;
      }
      xDown = null;
      yDown = null; 
    };

    /**
     * Takes carousel to the previous slide
     */
    const goToPreviousSlide = () => {
      let nextIndex = getIndexRelativeToCurrentSlide(-1);
      setActiveSlide(nextIndex);
    };

    /**
     * Takes carousel to the next slide
     */
    const goToNextSlide = () => {
      let nextIndex = getIndexRelativeToCurrentSlide(1);
      setActiveSlide(nextIndex);
    };

    /**
     * Gets the current carousel index
     */
    const getCurrentSlideIndex = () => {
      const currentItem = document.querySelector('.gs-testimony-content.--selected');
      return parseInt(currentItem.dataset.indexval);
    };

    /**
     * Gets the slide index that is *step* slides over relative to the currently active slide
     * @param {number} step The number of slides relative to the current slide (1 is right and -1 is left)
     * @returns The absolute slide index relative 
     */
    const getIndexRelativeToCurrentSlide = (step) => {
      const currentItemIndex = getCurrentSlideIndex();
      const numSlides = getNumSlides();
      return (currentItemIndex + step + numSlides) % numSlides;
    };

    /**
     * Gets the total number of slides in the carousel
     * @returns {number} The number of slides
     */
    const getNumSlides = () => {
      const allItems = document.querySelectorAll('.gs-testimony-content');
      return allItems.length;
    };

    const resetSlidePositions = () => {
      document.querySelectorAll('.gs-testimony-content').forEach(slide => { slide.style.left = '0px' })
    };

    /**
     * Sets the carousel slide at the given index to active
     * @param {number} index The slide to become active
     */
    const setActiveSlide = index => {
      // Change active slide
      const currentItem = document.querySelector('.gs-testimony-content.--selected');
      currentItem.classList.remove('--selected');
      document.querySelector('#gs_testimony_content_' + index).classList.add('--selected');
      
      // Reset any dragged slides to the center again
      resetSlidePositions();

      // update pagination dots
      const allIndicators = document.querySelectorAll('.gs-testimony-indicator');
      const selectedIndicator = document.querySelector('.gs-testimony-indicator.--active');
      if (!allIndicators[index].classList.contains('--active')) {
        selectedIndicator.classList.remove('--active');
        allIndicators[index].classList.add('--active');
      }
    };

    /**
     * Handles the user's intent to change the carousel to the given index
     * @param {number} index The index of the slide to jump to
     */
    const handleIndicatorClick = index => {
      const currentItemIndex = getCurrentSlideIndex();
      if (currentItemIndex !== index) {
        setActiveSlide(index);
      }
    };

    /**
     * Handles the users intent to jump to the next or previous slide
     * @param {string} direction "left" or "right" indicates which arrow was clicked
     */
    const handleArrowClick = direction => {
      if(direction === 'left') {
        goToPreviousSlide();
      } else {
        goToNextSlide();
      }
    };

    if (document.getElementById(leftArrow)) {

      const component = document.querySelector('.gs-testimony-inner');
      document.getElementById(leftArrow).addEventListener('click', () => {
        handleArrowClick('left');
      });

      document.getElementById(rightArrow).addEventListener('click', () => {
        handleArrowClick('right');
      });

      component.addEventListener('touchstart', handleTouchStart, false);        
      component.addEventListener('touchmove', handleTouchMove, false);
      component.addEventListener('touchend', handleTouchEnd, false);

      [...document.querySelectorAll('.gs-testimony-indicator')].map((item, index) => {
        item.addEventListener('click', () => {
          handleIndicatorClick(index);
        });
      });
    }
  }
};
