// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   05/11/2022    ZSunOo        77752           Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import moment from 'moment';

export class Data {
  /**
   * Parse JSON date string as UTC into a moment Date wrapper object
   * @param { string } str The date string
   * @returns The parsed date (moment object) or empty string
   */
  parseDate(str) {
    const regex = new RegExp(/Date\((\d+)\)/).exec(str);
    if (regex.length > 0) {
      return moment.utc(parseInt(regex[1]));
    } else {
      return '';
    }
  }

  /**
   * Takes raw data from the API and processes it so it can be rendered by the LineGraph class
   * @param {string} json 
   * @returns the processed data
   */
  process(json) {
    const that = this;
    const values = [];
    const distributions = [];
    const distributionAssetValue = function (d) {
      for (let i = 0; i < values.length; i++) {
        if (moment(values[i].date).isSame(d.date, 'day')) {
          return values[i];
        }
      }

      return undefined;
    };

    json.Values.forEach(function (v) {
      values.push({
        value: v.SharePrice,
        date: that.parseDate(v.TradeDate)
      });
    });

    json.Distributions.forEach(function (d) {
      distributions.push({
        rate: d.Rate,
        date: that.parseDate(d.PayDate),
        type: d.DistributionType,
        dateRecord: that.parseDate(d.RecordDate)
      });
    });

    distributions.forEach(function (d) {
      const NAVValue = distributionAssetValue(d);
      if (NAVValue) {
        d.value = NAVValue.value;
        d.date = NAVValue.date;
      }
    });

    return {
      values: values,
      distributions: distributions
    };
  }
}
