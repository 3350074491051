////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#        Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  03/29/2022    BBARRON       82643         Initial create
//  03/31/2022    BBARRON       82812         changed module name to lowercase to match other modules
//  05/26/2022    BBARRON       86063         Fix footer collapsing. Only show controls if footnotes are too tall
//  08/04/2022    GDEME         89643         Fund Details Pages (all) - expand/collapse functionality
//  08/07/2022    BBARRON       89643         Overhaul expanding/collapsing footnotes. Default to expanded, disable collapsing if too short
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const factsheetVerticalTableScripts = {
    loadScripts: () => {

      const defaultToCollapsed = false;
      const expandCollapseThreshold = 180;

      const handleExpandClick = (evt) => {
        evt.preventDefault();
        let parent = evt.currentTarget.parentNode;
        if(parent.classList.contains('factsheet-vtable-footer')) {
            parent.classList.remove('factsheet-vtable-footer--collapsed');
            parent.classList.add('factsheet-vtable-footer--expanded');
        }
      };

      const handleCollapseClick = (evt) => {
        evt.preventDefault();
        let parent = evt.currentTarget.parentNode;
        if(parent.classList.contains('factsheet-vtable-footer')) {
            parent.classList.add('factsheet-vtable-footer--collapsed');
            parent.classList.remove('factsheet-vtable-footer--expanded');
        }
      };
  
      const expandButtons = document.querySelectorAll('.factsheet-vtable-footer-controls--collapsed');
      expandButtons.forEach(button => {
        button.addEventListener('click', handleExpandClick, false);
      });

      const collapseButtons = document.querySelectorAll('.factsheet-vtable-footer-controls--expanded');
      collapseButtons.forEach(button => {
        button.addEventListener('click', handleCollapseClick, false);
      });

      
      // Only show expand/collapse controls if footer is high enough to justify this.
      // If present, remove these buttons if the footnotes are too short.
      function resetFooters() {
        const tableFooters = document.querySelectorAll('.factsheet-vtable-footer');
        tableFooters.forEach(footer => {
          var footnotes = footer.querySelector('.factsheet-vtable-footnotes');
          if(footnotes.offsetHeight > expandCollapseThreshold) {
            if(defaultToCollapsed){
              footer.classList.add('factsheet-vtable-footer--collapsed');
            }else{
              footer.classList.add('factsheet-vtable-footer--expanded');
            }          
          } else {
            footer.classList.remove('factsheet-vtable-footer--collapsed');
            footer.classList.remove('factsheet-vtable-footer--expanded');
          }
        });
      }

      resetFooters();

      window.addEventListener('resize', function() {
        this.window.requestAnimationFrame(resetFooters);
      });
    }
};
  