// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   11/02/2020    JJacob        49830           Initial Create
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const toggleRadioScripts = {
  loadScripts: () => {
    /**
     * EventListener callback which fires when a radio button is clicked. Identifies
     * the clicked radio button and sets the appropriate "visibility" classes on the
     * forms to either show/hide based on active toggle
     *
     * @param {string} id Identifier for the selected radio
     */
    const handleRadioChange = id => {
      if (id === 'one') {
        document.querySelector('.gs-form-cta-organization').classList.remove('--selected');
        document.querySelector('.gs-form-cta-individual').classList.add('--selected');
      } else if (id === 'two') {
        document.querySelector('.gs-form-cta-individual').classList.remove('--selected');
        document.querySelector('.gs-form-cta-organization').classList.add('--selected');
      }
    };

    if (document.getElementById('gs_form_cta_radio_individual')) {
      document
        .getElementById('gs_form_cta_radio_individual')
        .addEventListener('change', () => handleRadioChange('one'));
      document
        .getElementById('gs_form_cta_radio_organization')
        .addEventListener('change', () => handleRadioChange('two'));
    }
  }
};
