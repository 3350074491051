////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   11/22/2021    BBARRON       74527           Initial Create
//   01/06/2022    BBARRON       77108           pass data in as parameter or get from hidden data tables on page from CMS
//   01/12/2022    BBARRON       77743           Fix link styles and url if not starting with http
//   04/27/2022    BBARRON       84447           Remove unused variable
//   05/18/2023    BBARRON       105921          Renamed and updated data model to include link text, isExternal and newTab
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const interactiveMapScripts = {
    loadScripts: () => {
        const mapInfoCloseBtnClass = 'gs-map-closebtn';
        const stateClass = 'state';
        const activeStateClass = 'state-active';

        const defaultFill = '#d2d2d2'; // Grayscale Holy Ghost
        const activeFill = '#007B4B'; // Brand Green Success

        const infoModalCloseButtons = document.querySelectorAll(`.${mapInfoCloseBtnClass}`);
        const stateElements = Array.prototype.slice.call(document.querySelectorAll(`.${stateClass}`));


        /**
         * Handle the case when the info modal close button is clicked
         * @param {ClickEvent} evt 
         */
        function handleCloseClick(evt) {
            evt.preventDefault();
            let stateInfos = document.querySelectorAll('.gs-map-stateinfo');
            stateInfos.forEach(s => {
                s.classList.add('hidden');
            });
            updateMap(null, stateElements);
        }

        /**
         * Handle the case when a US state is clicked
         * @param {ClickEvent} evt 
         */
        function handleStateClick(evt) {
            evt.preventDefault();
            const stateCode = evt.target.dataset.name;
            let stateInfos = document.querySelectorAll('.gs-map-stateinfo');
            stateInfos.forEach(s => {
                if(s.dataset.abbreviation === stateCode) {
                    s.classList.remove('hidden');
                } else {
                    s.classList.add('hidden');
                }
            });
            updateMap(stateCode, stateElements);
        }

        

        /**
         * Updates the map with the current active state
         * @param {*} activeState The new active state code
         * @param {*} stateElements The list of state elements
         */
        function updateMap(activeState, stateElements) {
            stateElements.forEach(state => {
                let isActive = false;
                if(activeState) {
                    let currentState = state.dataset.name;
                    isActive = currentState === activeState;
                }
                
                updateStateStatus(state, isActive);
            });
        }

        /**
         * Updates the active/inactive status of a single state
         * @param {HtmlElement} stateElement 
         * @param {boolean} isActive 
         */
        function updateStateStatus(stateElement, isActive) {
            if(isActive) {
                stateElement.setAttribute('fill', activeFill);
                stateElement.classList.remove(activeStateClass);
            } else {
                stateElement.setAttribute('fill', defaultFill);
                stateElement.classList.remove(activeStateClass);
            }
        }

        // Wire up the events
        if(infoModalCloseButtons && stateElements && stateElements.length > 0) {
            stateElements.forEach(stateEl => {
                stateEl.addEventListener('click', handleStateClick, false);
            });

            infoModalCloseButtons.forEach(btn => {
                btn.addEventListener('click', handleCloseClick, false);
            });
            
        }
    }
  };